import sortBy from 'sort-by';

import {
    LOGIN_POST
} from '../actions/ActionsTypes';



const INITIAL_STATE = {
    isAuthenticated: sessionStorage.getItem("isAuthenticated") || false
    , codigoConsultor: sessionStorage.getItem("codigoConsultor") || ""
    , apelidoConsultor: sessionStorage.getItem("apelidoConsultor") || ""
    , tipousuario: sessionStorage.getItem("tipousuario") || ""
    , emailConsultor: sessionStorage.getItem("emailConsultor") || ""
    , token: sessionStorage.getItem("tokenAPI") || ""
}

export default (state = INITIAL_STATE, action) => {
        switch (action.type) {

        //desativado
        case LOGIN_POST:

            return { ...state, isAuthenticated: action.isAuthenticated
                , codigoConsultor: action.codigoConsultor
                , apelidoConsultor : action.apelidoConsultor
                , tipousuario: action.tipousuario
                , emailConsultor: action.emailConsultor 
                , token: action.token}
        default:
            return state;
    }
}