import {
    WHATSAPP_LIST_CONTATOS,
    WHATSAPP_CHANGE_USUARIOS,
    WHATSAPP_LIST_STATUS,
    WHATSAPP_CHANGE_FIELD,
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    contatos: [],
    mensagem: "",
    status: "",
    codparc: ''
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case WHATSAPP_LIST_CONTATOS:
            return { ...state, contatos: action.payload }
        case WHATSAPP_CHANGE_FIELD:
            return { ...state, [action.field]: action.payload }

        case WHATSAPP_CHANGE_USUARIOS:
            return {
                ...state,
                contatos: state.contatos.map(
                    (item) =>
                        item.codcontato === action.codcontato ?
                            { ...item, [action.field]: action.payload }
                            : item
                )
            }

        case WHATSAPP_LIST_STATUS:
            return { ...state, status: action.status, mensagem: action.mensagem }

        // case PERMISSOES_EDITING_USUARIOS:
        //     return {
        //         ...state,
        //         usuarios: state.usuarios.map(
        //             (usuarios) => usuarios.usupedfbitsid === action.payload ?
        //                 { ...usuarios, isediting: action.isediting }
        //                 : usuarios
        //         )
        //     }

        // case PERMISSOES_CHANGE_USUARIO_SAVING:
        //     return {
        //         ...state,
        //         usuarios: state.usuarios.map(
        //             (usuarios, i) => usuarios.usupedfbitsid === action.payload ?
        //                 { ...usuarios, issaving: action.issaving }
        //                 : usuarios
        //         )
        //     }


        default:
            return state;
    }
}