import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
var moment = require('moment');
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
import { kpivisitasListAction } from '../../actions/KpivisitasActions'

function CardGraph(props) {
    const [hideLines, setHideLines] = useState(false);
    const { kpivisitas } = props;

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        responsive: true,
        animation: {
            easing: 'easeInOutQuart',
        }
    };

    // const colorPalette = [
    //     '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
    //     '#8B0000', '#4682B4', '#32CD32', '#FF4500', '#DA70D6', '#010bf6',
    //     '#692a00', '#FFD700', '#CD5C5C', '#00FA9A', '#4169E1', '#FA8072',
    //     '#2E8B57', '#D2691E'
    // ];

    const arrayAnos = [...new Set(kpivisitas.map(item => moment(item.dt_ref).format('YYYY/MM')))];
    arrayAnos.sort((a, b) => a.localeCompare(b));

    const arrayAnosFiltrado = arrayAnos.slice(0, arrayAnos.length - 1);
    const labels = arrayAnosFiltrado;
    const pontosTotal = kpivisitas
        .sort((a, b) => new Date(a.dt_ref) - new Date(b.dt_ref)) // Ordena pela data mais antiga primeiro
        .map(item => item.pontos_total);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Pontos Total',  // Label da linha no gráfico
                data: pontosTotal,  // Valores de pontos_total
                fill: false,  // Não preencher sob a linha
                backgroundColor: '#33FF57',  // Cor de fundo dos pontos
                borderColor: '#33FF57',  // Cor da linha
                tension: 0.1,  // Suavização da linha
                pointRadius: 5, // Aumenta o tamanho dos pontos
                pointHoverRadius: 7
            }
        ]
    };

    const handleHideLines = () => {
        setHideLines(!hideLines);
    };

    return (
        <div style={{
            marginTop: '50px',
            boxShadow: '-1px 8px 8px 5px #ccc',
            border: '1px solid #ccc',
            padding: '20px',
            borderRadius: 10
        }}>
            {/* <button style={{ all: 'unset', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }} onClick={handleHideLines}>
                <div style={{ width: '42px', height: '15px', backgroundColor: '#000', marginRight: 5 }} />
                <span style={{ color: '#656565', fontSize: 12, textDecoration: hideLines ? 'line-through' : 'none' }}>Ocultar linhas</span>
            </button> */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <label style={{ fontSize: 20 }}><b>Gráfico da sua pontuação total</b></label>
            </div>
            <Line data={data} width={450} heigth={450} options={options} />
        </div>
    );

}

const mapStateToProps = (state) => ({
    kpivisitas: state.KpiVisitasReducer.kpivisitas,
})

export default connect(mapStateToProps, {
    kpivisitasListAction
}
)(CardGraph);
