export const ClienteEntity = {
    codparc: 0,
    nome: '',
    cnpj: '',
    email: '',
    telefoneprincipal: '',
    telefonesecundario: '',
    justificativa: '',
    codvend: '',
    ramoatividade: '',
    produtosquetrabalha: '',
    produtosquepretende: '',
    codconsultor: 0,

    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: ''


}