import {
    LOGIN_POST

} from './ActionsTypes'

import * as Api from '../utils/API';
import sortBy from 'sort-by';

export const loginValidAction = (isAuthenticated, history) => {
    return (dispatch) => {
        if(!isAuthenticated)history.push(`/`);    
    };
}

export const loginPostAction = (formData) => (dispatch) =>
    new Promise(function (resolve, reject) {
        var token;

        Api.postToken(formData).then(result =>{
            token = result.access_token;

            Api.postLogin(formData).then(result => {
                if(result.status==='Allowed'){
                    dispatch({ type: LOGIN_POST, isAuthenticated: true
                        , codigoConsultor:result.codigoconsultor 
                        , apelidoConsultor:result.apelido
                        , tipousuario:result.tipousuario 
                        , emailConsultor:result.email
                        , token: token
                    })
    
                    sessionStorage.setItem("isAuthenticated", true );
                    sessionStorage.setItem("codigoConsultor", result.codigoconsultor);
                    sessionStorage.setItem("apelidoConsultor", result.apelido);
                    sessionStorage.setItem("tipousuario", result.tipousuario);
                    sessionStorage.setItem("emailConsultor", result.email);
                    sessionStorage.setItem("tokenAPI", token);
                    localStorage.setItem('tokenAPI', token);
                    resolve({status:"OK", msg:""})
                    //history.push(`/PainelPedidos`);
                }else{
                    dispatch({ type: LOGIN_POST, isAuthenticated: false, codigoConsultor:"", tipousuario:"" })
                    sessionStorage.setItem("isAuthenticated",false)
                    sessionStorage.setItem("codigoConsultor","");
                    sessionStorage.setItem("tipousuario","");
                    resolve({status:"Invalid", msg:"Usuário ou senha inválidos"})
                    //history.push(`/`);
                }            
            });

        })

        
    });






