import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom'

import LoginForm from './components/loginform/LoginForm'
import Main from './components/main/Main'


import { Container } from 'semantic-ui-react'




class App extends Component {
  render() {
    return (

      <div>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/Main" element={<Main />} />
        </Routes>
      </div>


    );
  }
}

export default App;
