import sortBy from 'sort-by';

import {
    NOVOCLIENTE_RAMOATIVIDADE_LIST
    , NOVOCLIENTE_PRODUTOSTRABALHA_LIST 
    , NOVOCLIENTE_PRODUTOSPRETENDE_LIST
    , NOVOCLIENTE_VENDEDORES_LIST

    , NOVOCLIENTE_CHANGE

    , NOVOCLIENTE_VALIDACAO_ADD
    , NOVOCLIENTE_VALIDACAO_REMOVE
    , NOVOCLIENTE_LOGSCREEN 
    , NOVOCLIENTE_SAVING

    , NOVOCLIENTE_EXISTECODPARC
    , NOVOCLIENTE_CHANGETAB
} from '../actions/ActionsTypes';

import { ClienteEntity } from '../entities/ClienteEntity'

const INITIAL_STATE = {
    ClienteEntity
     ,ramoatividade: []
    , produtostrabalha: []
    , produtospretende: []
    , vendedores: []
    , validacao: []
    , isSaving: false
    , logScreen: []
    , existecodparc:0
    , tabindex:0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case NOVOCLIENTE_RAMOATIVIDADE_LIST:
            return { ...state, ramoatividade: action.payload }
        
        case NOVOCLIENTE_CHANGETAB:
            return { ...state, tabindex:action.payload}

        case NOVOCLIENTE_EXISTECODPARC:
            return { ...state, existecodparc: action.payload }

        case NOVOCLIENTE_PRODUTOSTRABALHA_LIST:
            return { ...state, produtostrabalha: action.payload }
       
        case NOVOCLIENTE_PRODUTOSPRETENDE_LIST:
            return { ...state, produtospretende: action.payload }

        case NOVOCLIENTE_CHANGE:
            return { ...state, ClienteEntity: { ...state.ClienteEntity, [action.field]: action.payload } }

        case NOVOCLIENTE_VENDEDORES_LIST:
            return { ...state, vendedores: action.payload }

        case NOVOCLIENTE_VALIDACAO_ADD:
            return { ...state, validacao: [...state.validacao, action.payload] }

        case NOVOCLIENTE_VALIDACAO_REMOVE:
            return { ...state, validacao: [] }

        case NOVOCLIENTE_SAVING:
            return { ...state, isSaving: action.payload }
            
        case NOVOCLIENTE_LOGSCREEN:
            return { ...state, logScreen: action.payload }

        default:
            return state;
    }
}