import React from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function KpiSkeleton() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                    <div>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={20} width={250} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Skeleton height={40} width={200} />
                        <Skeleton height={40} width={250} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                        <Skeleton height={200} width={250} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '20px' }}>
                        <Skeleton height={400} width={1050} borderRadius={8} style={{ marginRight: 20, marginBottom: 20 }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function KpiSkeletonMobile() {

    return (
        <div className="skeleton-container">
            <div className="skeleton-title">
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                    <div>
                        <Skeleton height={20} width={200} />
                        <Skeleton height={20} width={250} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Skeleton height={40} width={100} />
                        <Skeleton height={40} width={150} />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px', justifyContent: 'space-between' }}>
                        <Skeleton height={200} width={150} borderRadius={8} style={{ marginBottom: 20 }} />
                        <Skeleton height={200} width={150} borderRadius={8} style={{ marginBottom: 20 }} />
                        <Skeleton height={200} width={150} borderRadius={8} style={{ marginBottom: 20 }} />
                        <Skeleton height={200} width={150} borderRadius={8} style={{ marginBottom: 20 }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export { KpiSkeleton, KpiSkeletonMobile };
