import * as Api from '../utils/API';
var sortBy = require('sort-by')
import {
    WHATSAPP_LIST_CONTATOS,
    WHATSAPP_CHANGE_USUARIOS,
    WHATSAPP_LIST_STATUS,
    WHATSAPP_CHANGE_FIELD

} from '../actions/ActionsTypes';

export const whatsappListaContatosAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getListaContatos(entidade).then(data => {

            let contatos = []
            let mensagem = data[0].mensagem
            let status = data[0].status

            contatos = data;

            if(contatos[0]?.dados!=null && contatos[0]?.dados!=undefined){
                contatos[0].dados = JSON.parse(contatos[0].dados);
                contatos[0].dados.sort(sortBy('-codcontato'));
            }else{
                contatos[0].dados = [];
            }
            
            dispatch({ type: WHATSAPP_LIST_STATUS, status: status, mensagem: mensagem })
            dispatch({ type: WHATSAPP_LIST_CONTATOS, payload: contatos[0].dados })
            resolve([])
        }).catch(error => {
            console.log("error", error)
            reject(error);
        })


    });

export const whatsappCriaAction = (entidade, acao) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        // const { codparc } = getState().WhatsappReducer;


        // dispatch(whatsappListaContatosAction({ codparc }));


        Api.postCriaContato(entidade)
            .then((response) => {
                console.log(response[0].status)
                if (response[0].status === "OK") {
                    // Após a criação bem-sucedida, chame a action para atualizar a lista de ajudantes
                    resolve([]);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve([]);
            });
    });

export const whatsappContatoChangeAction = (e, contato) => {
    return {
        type: WHATSAPP_CHANGE_USUARIOS
        , field: e.target.name
        , payload: e.target.value
        , codcontato: contato.codcontato
    }

}

export const whatsappContatoChangeFieldAction = (e) => {
    return {
        type: WHATSAPP_CHANGE_FIELD
        , field: e.target.name
        , payload: e.target.value
    }

}

export const whatsappContatoAtualizaAction = (entidade) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)

        // const { codparc } = getState().WhatsappReducer;

        Api.postContatoAtualiza(entidade).then((response) => {
            if (response[0].status === "OK") {
                // dispatch(whatsappListaContatosAction({ codparc }));
                resolve([]);
            }
        }).catch((error) => {
            console.log(error);
            resolve([]);
        });
    });

export const whatsappContatoExcluiAction = (entidade) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        // const { codparc } = getState().WhatsappReducer;


        Api.postContatoExclui(entidade).then((response) => {
            console.log(response)
            if (response[0].status === "OK") {
                // dispatch(whatsappListaContatosAction({ codparc }));
                resolve([]);
            }
        }).catch((error) => {
            console.log(error);
            resolve([]);
        });
    });