import * as Api from '../utils/API';
import {
    KPIVISITAS_LIST, KPIVISITAS_LOADING
} from '../actions/ActionsTypes';

export const kpivisitasListAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getKpiConsultor(entidade).then(dados => {
            dispatch({ type: KPIVISITAS_LIST, payload: dados })
            dispatch({ type: KPIVISITAS_LOADING, payload: false })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
