import React from 'react';
import { Icon } from 'semantic-ui-react';

function CardDashboard(props) {

    return (
        <div style={{
            background: `${props.cor === 'azul' ? 'linear-gradient(0deg, #0076c6 40%, #005792)' :
                props.cor === 'azul_claro' ? 'linear-gradient(0deg, #a2ddf9 50%, #7BC8F6)' :
                    props.cor === 'vermelho' ? 'linear-gradient(0deg, #f05661 60%, #E71D36)' :
                        props.cor === 'vermelho_claro' ? 'linear-gradient(0deg, #ff8ba1 50%, #FF6384)' :
                            props.cor === 'branco' ? 'linear-gradient(0deg, #ffffff, #e3e5f8)' : 'none'}`,
            padding: 24, marginTop: '20px',
            paddingLeft: `${props.mobile ? '15px' : '24px'}`,
            borderRadius: 6,
            display: 'flex',
            flexDirection: 'row',
            marginRight: `${props.mobile ? '0px' : '20px'}`,
            boxShadow: '10px 3px 10px #ccc',
            width: `${props.mobile ? '160px' : '230px'}`
        }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100px' }}> <b>{props.nome}: </b></div>
                <div>
                    <div>
                        {props.showPremio === true && (
                            <div style={{ width: '150px', marginRight: -40, marginTop: 10 }}>
                                <span style={{ fontSize: '16px', marginTop: '16px' }}> <b>Prêmio: <span style={{ fontSize: '12px' }}>{props.iconPremio}{props.premio !== null && props.premio !== undefined ? props.premio : '-'}</span></b></span>
                            </div>
                        )}
                    </div>

                    <div style={{ width: props.mobile ? '130px' : '145px', display: 'flex', flexDirection: 'row', marginRight: -40, marginTop: 5 }}>
                        <div style={{ width: '500px' }}>
                            <div>
                                <span style={{ fontSize: props.ismesatual ? '12px' : '16px', marginTop: '16px' }}> <b>Realizado:</b> </span>
                                <span style={{ fontSize: '12px' }}>{props.iconQtde}{props.qtde !== null && props.qtde !== undefined ? props.qtde : '-'}</span>
                            </div>
                            {props.ismesatual && (
                                <div>
                                    <span style={{ fontSize: props.ismesatual ? '12px' : '16px', marginTop: '16px' }}> <b>Projetado:</b> </span>
                                    <span style={{ fontSize: '12px' }}>{props.ismesatual && (props.projetada !== null && props.projetada !== undefined ? props.projetada : '-')}</span>
                                </div>
                            )}
                        </div>
                        {props.ismesatual && props.nome !== 'Meta' && (
                            <div style={{ borderLeft: '1px solid  #0d2636', paddingLeft: props.mobile ? '7px' : '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <span style={{ fontSize: props.ismesatual ? '12px' : '16px' }}> <b>Meta</b> </span>
                                <span style={{ fontSize: '12px' }}>{props.ismesatual && (props.meta !== null && props.meta !== undefined ? props.meta : props.nome === 'Meta' ? null : '-')}</span>
                            </div>
                        )}

                    </div>


                    <div style={{ width: '150px', marginRight: -20, marginTop: 5 }}>
                        <span style={{ fontSize: props.ismesatual ? '14px' : '16px', marginTop: '16px' }}> <b>Pontos: <span style={{ fontSize: '12px' }}>{props.iconPontos}{props.pontos !== null ? props.pontos : '-'}</span>
                            {/* {props.ismesatual && (props.pontosprojetada !== null && props.pontosprojetada !== undefined ? '/' + props.pontosprojetada : null)}{props.ismesatual && (props.pontosmeta !== null && props.pontosmeta !== undefined ? '/' + props.pontosmeta : null)} */}
                        </b> </span>

                    </div>
                </div>
            </div>
            {
                !props.mobile && (
                    <>
                        {props.nome.includes('Cliente') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='group' size='large' color='black' />
                            </div>
                        )}
                        {props.nome.includes('Visitas') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='suitcase' size='large' color='black' />
                            </div>
                        )}
                        {props.nome.includes('Meta') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='line graph' size='large' color='black' />
                            </div>
                        )}
                        {props.nome.includes('Faturamento') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='dollar' size='large' color='black' />
                            </div>
                        )}
                        {props.nome.includes('Venda') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='checkmark' size='large' color='black' />
                            </div>
                        )}
                        {props.nome.includes('Pontos') && (
                            <div style={{ marginLeft: '30px' }}>
                                <Icon name='favorite' size='large' color='black' />
                            </div>
                        )}
                    </>
                )
            }
        </div >
    );

}

export default CardDashboard;
