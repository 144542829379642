import {
    NOVOCLIENTE_RAMOATIVIDADE_LIST
    , NOVOCLIENTE_PRODUTOSTRABALHA_LIST
    , NOVOCLIENTE_PRODUTOSPRETENDE_LIST
    , NOVOCLIENTE_VENDEDORES_LIST

    , NOVOCLIENTE_CHANGE

    , NOVOCLIENTE_VALIDACAO_ADD
    , NOVOCLIENTE_VALIDACAO_REMOVE
    , NOVOCLIENTE_LOGSCREEN
    , NOVOCLIENTE_SAVING

    , NOVOCLIENTE_EXISTECODPARC
    , NOVOCLIENTE_CHANGETAB

} from './ActionsTypes'


import * as Api from '../utils/API';



export const novoClienteChangeTabAction = (posicao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: NOVOCLIENTE_CHANGETAB, payload: posicao })
        resolve([])
    }
    );


export const novoClienteDocumentoAction = (documento, tipo) => (dispatch) =>
    new Promise(function (resolve, reject) {

        if (documento != 0 && tipo != 0) {
            Api.getDocumento(documento, tipo).then(dados => {

                if (dados[0].item != 0) {
                    dispatch({ type: NOVOCLIENTE_EXISTECODPARC, payload: dados[0].item })
                } else {
                    dispatch({ type: NOVOCLIENTE_EXISTECODPARC, payload: 0 })
                }

                resolve([])
            })
        }else{
            dispatch({ type: NOVOCLIENTE_EXISTECODPARC, payload: 0 })
        }


    }
    );

export const novoClienteCEPAction = (cep) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getCEP(cep).then(dados => {

            if (dados.cep != null) {
                dispatch({ type: NOVOCLIENTE_CHANGE, field: 'cep', payload: dados.cep })
                dispatch({ type: NOVOCLIENTE_CHANGE, field: 'endereco', payload: dados.rua })
                dispatch({ type: NOVOCLIENTE_CHANGE, field: 'bairro', payload: dados.bairro })
                dispatch({ type: NOVOCLIENTE_CHANGE, field: 'cidade', payload: dados.cidade })
                dispatch({ type: NOVOCLIENTE_CHANGE, field: 'estado', payload: dados.uf })
            }


            resolve([])

        })

    }
    );



export const novoClienteHandleChangeAction = (event) => {
    if (event.target.name == 'nome' || event.target.name == 'endereco' || event.target.name == 'bairro' || event.target.name == 'cidade' || event.target.name == 'complemento') {
        return {
            type: NOVOCLIENTE_CHANGE
            , field: event.target.name
            , payload: event.target.value.toString()
        }
    } else {
        return {
            type: NOVOCLIENTE_CHANGE
            , field: event.target.name
            , payload: event.target.value.toString().trim()
        }
    }

}

export const novoClienteAddValidacao = (id, campo, mensagem) => {
    return {
        type: NOVOCLIENTE_VALIDACAO_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}


export const novoClienteRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: NOVOCLIENTE_VALIDACAO_REMOVE, payload: [] })
        resolve([])
    }
    );


export const novoClienteList = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getAllRamosProdutos().then(dados => {


            let ramoatividadeall = []
            if (dados.length > 0) {
                for (let r of dados[0].ramoatividadeall) {
                    ramoatividadeall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: NOVOCLIENTE_RAMOATIVIDADE_LIST, payload: ramoatividadeall })


            let produtosquetrabalhaall = []
            if (dados.length > 0) {
                for (let r of dados[0].produtosquetrabalhaall) {
                    produtosquetrabalhaall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: NOVOCLIENTE_PRODUTOSTRABALHA_LIST, payload: produtosquetrabalhaall })


            let produtosquepretendeall = []
            if (dados.length > 0) {
                for (let r of dados[0].produtosquepretendeall) {
                    produtosquepretendeall.push({ key: r.codtipo.toString(), text: r.tipoparceiro, value: r.codtipo.toString() })
                }
            }
            dispatch({ type: NOVOCLIENTE_PRODUTOSPRETENDE_LIST, payload: produtosquepretendeall })


            let vendedores = []
            vendedores.push({ key: "0", text: "Sem vendedor", value: "0" })
            if (dados.length > 0) {
                for (let r of dados[0].vendedores) {
                    vendedores.push({ key: r.codvend.toString(), text: r.apelido, value: r.codvend.toString() })
                }
            }
            dispatch({ type: NOVOCLIENTE_VENDEDORES_LIST, payload: vendedores })

            resolve([])

        })

    }
    );

export const novoClienteClearAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'codparc', payload: 0 })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'nome', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'cnpj', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'email', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'telefoneprincipal', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'telefonesecundario', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'codvend', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'ramoatividade', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'produtosquetrabalha', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'produtosquepretende', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'codconsultor', payload: 0 })

        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'cep', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'endereco', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'numero', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'complemento', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'bairro', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'cidade', payload: '' })
        dispatch({ type: NOVOCLIENTE_CHANGE, field: 'estado', payload: '' })





        resolve([])
    })



export const novoClienteSaveAction = (ClienteEntity) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: NOVOCLIENTE_SAVING, payload: true })

        Api.saveCliente(ClienteEntity).then(result => {
            dispatch({ type: NOVOCLIENTE_LOGSCREEN, payload: result })
            dispatch({ type: NOVOCLIENTE_SAVING, payload: false })

            if (result.length > 0 && result[0].status === 'OK') {
                dispatch(novoClienteClearAction())
                resolve({ gravar: "OK" })
            } else {
                resolve({ gravar: "ERRO" })
            }
        })

    });







