export const VISITAS_LIST = "VISITAS_LIST"
export const VISITAS_SAVING = "VISITAS_SAVING"
export const VISITAS_LOGSCREEN = "VISITAS_LOGSCREEN"
export const VISITAS_HISTORICO_LIST = "VISITAS_HISTORICO_LIST"
export const VISITAS_WHATSAPP_LIST = "VISITAS_WHATSAPP_LIST"


//LOGIN-------------------------------------------------------------------------------------------------
export const LOGIN_POST = "LOGIN_POST"
//------------------------------------------------------------------------------------------------------

export const VISITAS_RAMOATIVIDADE_LIST = "VISITAS_RAMOATIVIDADE_LIST"
export const VISITAS_PRODUTOSTRABALHA_LIST = "VISITAS_PRODUTOSTRABALHA_LIST"
export const VISITAS_PRODUTOSPRETENDE_LIST = "VISITAS_PRODUTOSPRETENDE_LIST"

export const VISITAS_RAMOATIVIDADE_SELECIONADO_LIST = "VISITAS_RAMOATIVIDADE_SELECIONADO_LIST"
export const VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST = "VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST"
export const VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST = "VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST"

export const VISITAS_CHANGE = "VISITAS_CHANGE"
export const VISITAS_JUSTIFIED = "VISITAS_JUSTIFIED"

export const VISITAS_ENTITY_LOAD = "VISITAS_ENTITY_LOAD"
export const VISITAS_VENDEDORES_LIST = "VISITAS_VENDEDORES_LIST"
export const VISITAS_VALIDACAO_ADD = "VISITAS_VALIDACAO_ADD"
export const VISITAS_VALIDACAO_REMOVE = "VISITAS_VALIDACAO_REMOVE"

export const VISITAS_WPP_ADD = "VISITAS_WPP_ADD"
export const VISITAS_CHANGE_WPP = "VISITAS_CHANGE_WPP"
export const VISITAS_WPP_REMOVE = "VISITAS_WPP_REMOVE"
export const VISITAS_ATUALIZAR_CAMPOS = "VISITAS_ATUALIZAR_CAMPOS"







export const NOVOCLIENTE_RAMOATIVIDADE_LIST = "NOVOCLIENTE_RAMOATIVIDADE_LIST"
export const NOVOCLIENTE_PRODUTOSTRABALHA_LIST = "NOVOCLIENTE_PRODUTOSTRABALHA_LIST"
export const NOVOCLIENTE_PRODUTOSPRETENDE_LIST = "NOVOCLIENTE_PRODUTOSPRETENDE_LIST"

export const NOVOCLIENTE_VENDEDORES_LIST = "NOVOCLIENTE_VENDEDORES_LIST"

export const NOVOCLIENTE_CHANGE = "NOVOCLIENTE_CHANGE"
export const NOVOCLIENTE_VALIDACAO_ADD = "NOVOCLIENTE_VALIDACAO_ADD"
export const NOVOCLIENTE_VALIDACAO_REMOVE = "NOVOCLIENTE_VALIDACAO_REMOVE"

export const NOVOCLIENTE_LOGSCREEN = "NOVOCLIENTE_LOGSCREEN"
export const NOVOCLIENTE_SAVING = "NOVOCLIENTE_SAVING"
export const NOVOCLIENTE_EXISTECODPARC = "NOVOCLIENTE_EXISTECODPARC"
export const NOVOCLIENTE_CHANGETAB = "NOVOCLIENTE_CHANGETAB"



export const PARCEIROS_LIST_SEARCH = "PARCEIROS_LIST_SEARCH"
export const BAIRROS_LIST_SEARCH = "BAIRROS_LIST_SEARCH"
export const PARCEIROS_UPDATE_BUSCA = "PARCEIROS_UPDATE_BUSCA"
export const PARCEIROS_VALIDACAO_ADD = "PARCEIROS_VALIDACAO_ADD"
export const PARCEIROS_VALIDACAO_REMOVE = "PARCEIROS_VALIDACAO_REMOVE"
export const PARCEIROS_ISSEARCHING = "PARCEIROS_ISSEARCHING"
export const PARCEIROS_ATUALPAG = "PARCEIROS_ATUALPAG"
export const PARCEIROS_ATUALCID = "PARCEIROS_ATUALCID"
export const PARCEIROS_TOTALPAG = "PARCEIROS_TOTALPAG"
export const PARCEIROS_BAIRRSELECT = "PARCEIROS_BAIRRSELECT"

//-------- reducer whatsapp --------------------

export const WHATSAPP_LIST_CONTATOS = "WHATSAPP_LIST_CONTATOS"
export const WHATSAPP_CHANGE_USUARIOS = "WHATSAPP_CHANGE_USUARIOS"
export const WHATSAPP_LIST_STATUS = "WHATSAPP_LIST_STATUS"
export const WHATSAPP_CHANGE_FIELD = "WHATSAPP_CHANGE_FIELD";





export const VISITAS_ATT_CODPARC = "VISITAS_ATT_CODPARC"
export const VISITAS_SRCH_CODPARC = "VISITAS_SRCH_CODPARC"


// --------------------- KPIVISITAS CONSULTOR ---------------//
export const KPIVISITAS_LIST = "KPIVISITAS_LIST";
export const KPIVISITAS_LOADING = "KPIVISITAS_LOADING";