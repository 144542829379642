import React, { useEffect, useState } from 'react'
import moment from 'moment';
import 'moment/locale/pt-br'
import { connect, useDispatch } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import { DebounceInput } from 'react-debounce-input';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types'

import {
    visitasListAction

    , visitasSaveAction
    , justificativaAction
    , visitasRamosProdutosClearAction
    , visitasHandleChangeAction
    , visitasSaveExtraAction
    , visitasAddValidacao
    , visitasRemoveValidacao
    , visitasWppAddAction
    , visitasWppRemoveAction,
    atualizarCampos
} from '../../actions/VisitasActions';

import {
    novoClienteDocumentoAction
} from '../../actions/NovoClienteActions';


import InputMask from 'react-input-mask';

import { loginValidAction } from '../../actions/LoginActions';
import LogScreen from '../logscreen/LogScreen';
import If from '../../utils/If';

import {
    Label, Segment,
    Message, Checkbox, Form
    , TextArea, Dropdown, Button, Icon, Card, Divider
} from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Visitas(props) {

    const [startDate, setStartDate] = useState(null);
    const [dtultivisita, setDtultivisita] = useState(null);
    const [initialSearch, setInitialSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [chkFinanceiro, setChkFinanceiro] = useState('');
    const [chkFechou, setChkFechou] = useState('N');
    const [chkFinanceiroMSG, setChkFinanceiroMSG] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [dropRamo, setDropRamo] = useState('');
    const [dropTrabalha, setDropTrabalha] = useState('');
    const [dropPretende, setDropPretende] = useState('');
    const [dropVendedores, setDropVendedores] = useState('');
    const [formError, setFormError] = useState(null);
    const [formErrorMessage, setFormErrorMessage] = useState([]);
    const [emailError, setEmailError] = useState(0);
    const [telefonePrincipalError, setTelefonePrincipalError] = useState(0);
    const [telefoneSecundarioError, setTelefoneSecundarioError] = useState(0);
    const [justificativa, setJustificativa] = useState('');
    const [nomewpp, setNomewpp] = useState('');
    const [emailwpp, setEmailwpp] = useState('');
    const [cargo, setCargo] = useState('');
    const [wpp, setWpp] = useState('');
    const [setErronomewpp, setSetErronomewpp] = useState(false);
    const [setErroemailwpp, setSetErroemailwpp] = useState(false);
    const [setErrocargo, setSetErrocargo] = useState(false);
    const [setErrowhatsapp, setSetErrowhatsapp] = useState(false);
    const [semwpp, setSemwpp] = useState('');
    const [dropClassificacaoFin, setDropClassificacaoFin] = useState('');
    const [visitaDados, setVisitaDados] = useState(null);
    const [chkTreinamentos, setChkTreinamentos] = useState();
    const [locPermitida, setLocPermitida] = useState(null);
    const [coordsUsuario, setCoordsUsuario] = useState({
        latitude: null,
        longitude: null
    })
    const [coordsCliente, setCoordsCliente] = useState({
        latitude: null,
        longitude: null
    })
    const [distancia, setDistancia] = useState(null);


    const {
        visitas,
        isSaving,
        isJustificativa,
        logScreen,
        codigoConsultor,
        isAuthenticated,
        tipousuario,
        historicodata,
        ramoatividade,
        ramoatividadeSelecionado,
        produtostrabalha,
        produtostrabalhaSelecionado,
        produtospretende,
        produtospretendeSelecionado,
        VisitaEntity,
        vendedores,
        validacao,
        existecodparc,
        whatsapp,
        codigparc,
        isLoadingSrch,
    } = props;

    const [isRamoAtividadeLoaded, setIsRamoAtividadeLoaded] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ramoatividadeSelecionado.length > 0) {
            const ramoValues = ramoatividadeSelecionado.join(', ');
            setDropRamo(ramoValues);
        }
        if (produtostrabalhaSelecionado.length > 0) {
            const trabalhaValues = produtostrabalhaSelecionado.join(', ');
            setDropTrabalha(trabalhaValues);
        }
        if (produtospretendeSelecionado.length > 0) {
            const pretendeValues = produtospretendeSelecionado.join(', ');
            setDropPretende(pretendeValues);
        }
        setDropVendedores(VisitaEntity.codvend);
        setChkTreinamentos(VisitaEntity.interessetreinamentos)

    }, [ramoatividade, ramoatividadeSelecionado, produtostrabalhaSelecionado, produtospretendeSelecionado]);

    const { atualizarCampos } = props;

    useEffect(() => {

        visitasHandleChangeAction({ target: { 'name': 'kminicial', 'value': 0 } })
        visitasHandleChangeAction({ target: { 'name': 'kmfinal', 'value': 0 } })
        visitasHandleChangeAction({ target: { 'name': 'km', 'value': 0 } })
        visitasHandleChangeAction({ target: { 'name': 'conversa', 'value': '' } })
        visitasHandleChangeAction({ target: { 'name': 'justificativa', 'value': '' } })

        loginValidAction(isAuthenticated, history);

        const today = new Date();
        setStartDate(today);

        const { whatsapp, codigparc, ramoatividadeSelecionado, produtostrabalhaSelecionado } = props;

        if (ramoatividadeSelecionado.length > 0 && !isRamoAtividadeLoaded) {
            const ramoValues = ramoatividadeSelecionado.join(', ');
            setDropRamo(ramoValues);
            setIsRamoAtividadeLoaded(true);
        }

        if (codigparc !== 0) {
            handleSearchQuery(codigparc);
            novoClienteDocumentoAction(0, 0);
        } else {
            // Se necessário, adicione alguma lógica adicional aqui
        }

        if (visitas.length > 0) {
            const strVendedor = visitas[0]?.codigovendedor || '';
            const strClassfin = visitas[0]?.classificacaofin || '';

            if (ramoatividadeSelecionado.length > 0) {
                const ramoValues = ramoatividadeSelecionado.join(', ');
                setDropRamo(ramoValues);
            }
            if (produtostrabalhaSelecionado.length > 0) {
                const trabalhaValues = produtostrabalhaSelecionado.join(', ');
                setDropTrabalha(trabalhaValues);
            }
            if (produtospretendeSelecionado.length > 0) {
                const pretendeValues = produtospretendeSelecionado.join(', ');
                setDropPretende(pretendeValues);
            }
            setDropVendedores(VisitaEntity.codvend);
            setDropClassificacaoFin(strClassfin);
        }

    }, []);

    useEffect(() => {
        const temCargoSemWhatsapp = whatsapp.filter(contact => contact.cargo === "Sem WhatsApp").length > 0;

        if (temCargoSemWhatsapp) {
            setSemwpp("S");
        } else {
            setSemwpp("N");
        }
    }, [whatsapp]);


    useEffect(() => {
        handleGetLocation();
        setCoordsCliente({
            latitude: VisitaEntity.lat,
            longitude: VisitaEntity.lng
        })
        setDistancia(Number(calcularDistancia(Number(VisitaEntity.lat), Number(VisitaEntity.lng), coordsUsuario.latitude, coordsUsuario.longitude)).toFixed(2))
    }, [VisitaEntity.lat, VisitaEntity.lng, coordsUsuario.latitude, coordsUsuario.long]);


    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Permissão de localização permitida');
                    setLocPermitida(true);
                    setCoordsUsuario({
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString()
                    })
                    console.log(`${position.coords.latitude}, ${position.coords.longitude}`);
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        setLocPermitida(false);
                        console.log('Permissão de localização negada pelo usuário.')
                    } else {
                        console.log(`Erro ao obter localização: ${error.message}`);
                    }
                }
            );
        }
    };

    function calcularDistancia(lat1, lon1, lat2, lon2) {
        const R = 6371; // Raio da Terra em km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distancia = R * c; // Distância em km
        return distancia;
    }

    // console.log('coordenadas do usuario ->', coordsUsuario.latitude, coordsUsuario.longitude);
    // console.log('coordenadas do cliente ->', coordsCliente.latitude + ', ' + coordsCliente.longitude);

    // console.log('diferença entre o usuario e a esplane ->', distancia)
    // console.log('PERMITIU LOCALIZAÇÃO ->', locPermitida)

    // console.log('VISITAS LENGTH ->', visitas.length);
    // console.log('VISITAS ATIVO ->', visitas?.[0]?.ativo);
    // console.log('INITIAL SEARCH ->', initialSearch);


    const { visitasListAction

        , visitasSaveAction
        , justificativaAction
        , visitasRamosProdutosClearAction
        , visitasHandleChangeAction
        , visitasSaveExtraAction
        , visitasAddValidacao
        , visitasRemoveValidacao
        , visitasWppAddAction
        , visitasWppRemoveAction } = props;


    const handleChangeChkIntADSite = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.interesseadsite === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'interesseadsite', 'value': valor.toString() } })
    }

    const handleChangeChkIntDisplay = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.interessedisplay === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'interessedisplay', 'value': valor.toString() } })
    }

    const handleChangeChkIntTreinamentos = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.interessetreinamentos === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'interessetreinamentos', 'value': valor.toString() } })
        setChkTreinamentos(valor)
    }


    const handleChangeChkAmostras = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.mostrouamost === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'mostrouamost', 'value': valor.toString() } })
    }


    const handleChangeChkB2B = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.mostroub2b === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'mostroub2b', 'value': valor.toString() } })
    }



    const handleChangeChkComContato = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.comfaltacontato === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'comfaltacontato', 'value': valor.toString() } })
    }


    const handleChangeChkfinreavaliacaodecredito = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.finreavaliacaodecredito === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'finreavaliacaodecredito', 'value': valor.toString() } })
    }
    const handleChangeChkfinpagardividas = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.finpagardividas === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'finpagardividas', 'value': valor.toString() } })
    }
    const handleChangeChklogmaterialdanificado = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.logmaterialdanificado === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'logmaterialdanificado', 'value': valor.toString() } })
    }

    const handleChangeChklogentregaobrasobeescada = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.logentregaobrasobeescada === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'logentregaobrasobeescada', 'value': valor.toString() } })
    }



    const handleChangeChknaoalteraconsultor = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && VisitaEntity.naoalteraconsultor === 'S')
            valor = 'N'
        visitasHandleChangeAction({ target: { 'name': 'naoalteraconsultor', 'value': valor.toString() } })
    }



    const handleForm = (event) => {
        // console.log(event.target.getAttribute('codcontato'));
        // console.log("   ###########################")
        visitasHandleChangeAction(event)
    }

    //DropDowns
    const handleVendedores = (e, { value }) => {
        setDropVendedores(value.toString());
        visitasHandleChangeAction({ target: { 'name': 'codvend', 'value': value.toString() } })
    }

    const handleClassificacaoFin = (e, { value }) => {
        setDropClassificacaoFin(value.toString());
        visitasHandleChangeAction({ target: { 'name': 'classificacaofin', 'value': value.toString() } })
    }

    const handleRamoAtividade = (e, { value }) => {
        setDropRamo(value.toString());
    }

    const handleProdutosTrabalha = (e, { value }) => {
        setDropTrabalha(value.toString());
    }

    const handleProdutosPretende = (e, { value }) => {
        setDropPretende(value.toString());
    }



    function handleChangeDate(date) {
        setStartDate(date);
    }

    const handleChange = (e, { value }) => setChkTreinamentos(value)



    const handleChangeChkFechou = (e, { value }) => {
        let valor = value;
        if (valor === 'S' && chkFechou === 'S')
            valor = 'N'
        setChkFechou(valor)
    }

    const handleChangeChkFinanceiro = (e, { value }) => {

        let valor = value
        if (valor === 'S' && chkFinanceiro === 'S') {
            valor = 'N'
        }
        if (valor.trim() === '') {
            valor = 'N'

        }
        if (chkFinanceiroMSG.trim() != '' && valor === 'N') {
            valor = 'S'

        }
        if (chkFinanceiroMSG.trim().length === 1 && valor === 'S') {
            valor = 'S'
            setChkFinanceiroMSG('')
        }

        setChkFinanceiro(valor)
    }

    const isEmail = (email = null) => {
        const { VisitaEntity } = props;
        if (VisitaEntity.isconsultor == 0 && email !== null) {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email.includes("@esplane")) {
                return false;
            }
            return regex.test(email);
        } else if (email === null) {
            return false
        }
        return true
    }

    const handleChangeMsgFinanceiro = (e, { value }) => {
        let valor = value


        if (valor.trim() != '') {
            setChkFinanceiro('S')

            setChkFinanceiroMSG(value)
        } else {
            setChkFinanceiro('N')
        }

    }

    const handleSearchQuery = (query) => {

        // removendo erro de todos campos ao pesquisar
        setSetErronomewpp(false);
        setSetErroemailwpp(false);
        setSetErrocargo(false);
        setSetErrowhatsapp(false);
        setIsLoadingSave(false);

        setNomewpp('');
        setEmailwpp('');
        setCargo('');
        setWpp('');
        setSemwpp('N')

        visitasRamosProdutosClearAction();

        // setDropRamo('');
        // setDropTrabalha('');
        // setDropPretende('');
        // setDropVendedores('');
        // setDropClassificacaoFin('');
        // if (ramoatividadeSelecionado.length > 0) {
        //     const ramoValues = ramoatividadeSelecionado;
        //     setDropRamo(ramoValues);
        // }
        // if (produtostrabalhaSelecionado.length > 0) {
        //     const trabalhaValues = produtostrabalhaSelecionado;
        //     setDropTrabalha(trabalhaValues);
        //     // console.log('PRODUTO TRABALHA ->', dropTrabalha);
        // }
        // if (produtospretendeSelecionado.length > 0) {
        //     const pretendeValues = produtospretendeSelecionado;
        //     setDropPretende(pretendeValues);
        //     // console.log('PRODUTO PRETENDE ->', dropPretende);
        // }

        setLoading(true);

        setChkFinanceiro('N');
        setChkFechou('N');
        setChkFinanceiroMSG('');


        if (query === '') {
            setInitialSearch(true);
            console.log('CAIU AQUI ANTES')
        }

        setSearchQuery(query);

        console.log('QUERY->', query)

        visitasListAction(query).then(result => {
            console.log('RESULT ->', result);
            if (result.length > 0) {
                setLoading(false)
                const datePicker = document.getElementsByClassName("react-datepicker__input-container")[0];
                datePicker?.childNodes?.[0].setAttribute("readOnly", true);

                //recuperando valores
                //03/02/2023 - deixamos de trazer a data da última visita para sempre trazer a data do dia atual.


                setDtultivisita(visitas?.[0]?.dataultimavisita)


                // var date_start = moment(new Date()).utc();
                // setStartDate(isNaN(date_start) ? null : date_start)
            }

            if (query != '' && result.length === 0) {
                setInitialSearch(false)
            }

            setLoading(false)


            let { ramoatividadeSelecionado
                , produtostrabalhaSelecionado
                , produtospretendeSelecionado } = props

            let countSelected = 0

            var strRamo = ''
            for (let i of ramoatividadeSelecionado) {
                if (countSelected > 0) {
                    strRamo = strRamo.concat(',' + i.toString())
                } else {
                    strRamo = strRamo.concat(i.toString())
                }
                countSelected++
            }

            var strTrabalha = ''
            countSelected = 0
            for (let i of produtostrabalhaSelecionado) {
                if (countSelected > 0) {
                    strTrabalha = strTrabalha.concat(',' + i.toString())
                } else {
                    strTrabalha = strTrabalha.concat(i.toString())
                }
                countSelected++
            }

            var strPretende = ''
            countSelected = 0
            for (let i of produtospretendeSelecionado) {
                if (countSelected > 0) {
                    strPretende = strPretende.concat(',' + i.toString())
                } else {
                    strPretende = strPretende.concat(i.toString())
                }
                countSelected++
            }

            var strVendedor = ''
            if (result.length > 0) {
                strVendedor = visitas[0]?.codigovendedor
            }

            var strClassfin = ''
            if (result.length > 0) {
                strClassfin = visitas[0]?.classificacaofin
            }

            // setDropRamo(ramoatividade);
            // setDropTrabalha(produtostrabalha);
            // setDropPretende(produtospretende);
            // setDropVendedores(strVendedor);
            setDropClassificacaoFin(strClassfin);
        })
    }

    const adicionaErro = (campo, mensagem) => {
        let mensagens = formErrorMessage
        if (mensagens.filter(a => a.item === campo).length === 0) {
            mensagens.push({ item: campo, mensagem: mensagem, status: 'Error' })
            setFormErrorMessage(mensagens)
        }
    }

    const removeErro = (campo) => {
        //console.log('remove:' + campo)
        //this.setState({ formErrorMessage: [] })
        let dados = formErrorMessage.filter(e => e.item !== campo)

        setFormErrorMessage(dados)
    }



    const validaForm = () =>
        new Promise(function (resolve, reject) {

            resolve([])
        }
        );

    // isEmail = (email = null) => {
    //     const { VisitaEntity } = props;
    //     if (VisitaEntity.isconsultor == 0) {
    //         const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //         if (email !== null) {
    //             if (email.includes("@esplane")) {
    //                 return false;
    //             }
    //             return regex.test(email) || email.includes('@esplane');
    //         }
    //     }
    //     return true
    // }


    const salvarVisita = (formData) => {

        if (isSaving === false) {
            setFormError(0)
            setFormError((error) => {
                // console.log('erro em tempo real 1:', error)
                return error;
            });
            // console.log('form error ->', formError);
            //this.setState({ formErrorMessage: [] })
            visitasRemoveValidacao().then(async result => {

                if (isEmail(VisitaEntity.email) === false) {
                    visitasAddValidacao(1, "E-mail", "É necessário preencher")
                    //this.adicionaErro("E-mail", "É necessário preencher com e-mail válido")
                    setEmailError(1)

                    setFormError(1);

                } else {
                    //this.removeErro("E-mail")
                    setEmailError(0)
                }

                if (VisitaEntity.telefoneprincipal != null) {
                    if (VisitaEntity.telefoneprincipal === '' || VisitaEntity.telefoneprincipal.toString().trim().length < 11) {
                        //this.adicionaErro("Telefone Principal", "É necessário preencher o campo")
                        visitasAddValidacao(2, "Telefone Principal", "É necessário preencher corretamente")
                        setTelefonePrincipalError(1);

                        setFormError(1);
                    } else {
                        // this.removeErro("Telefone Principal")
                        setTelefonePrincipalError(0)
                    }
                }
                if (VisitaEntity.telefonesecundario != null) {
                    if (VisitaEntity.telefonesecundario.toString().trim().length > 0 && VisitaEntity.telefonesecundario.toString().trim().length < 11) {
                        //this.adicionaErro("Telefone Secundário", "É necessário preencher o campo")
                        visitasAddValidacao(3, "Telefone Secundário", "Está inválido")
                        setTelefoneSecundarioError(1);

                        setFormError(1);
                    } else {
                        // this.removeErro("Telefone Secundário")
                        setTelefoneSecundarioError(0);
                    }
                }


                if (VisitaEntity.vendativo === "N") {
                    const codvendinativo = visitas[0].codigovendedor
                    if (codvendinativo === VisitaEntity.codvend) {
                        visitasAddValidacao(4, "Vendedor", "Selecione um vendedor ativo")

                        setFormError(1);
                    }

                }
                if (VisitaEntity.codvend.toString().trim() === "" || VisitaEntity.codvend.toString().trim() === "0") {

                    visitasAddValidacao(5, "Vendedor", "Selecione o vendedor que será responsável")

                    setFormError(1);
                }


                if (startDate === null) {
                    //this.adicionaErro("Telefone Secundário", "É necessário preencher o campo")
                    visitasAddValidacao(6, "Visita", "Informe a data da visita")

                    setFormError(1);
                }


                if (tipousuario == 'E') {
                    if (VisitaEntity.km.toString().trim() === "" || VisitaEntity.km.toString().trim() === "0") {
                        visitasAddValidacao(7, "KM", "Informe o km atual do veículo")

                        setFormError(1);
                    }
                }


                if (VisitaEntity.conversa.toString().trim() === "") {
                    visitasAddValidacao(8, "Relato", "Informe a ação/conversa realizada com o cliente")

                    setFormError(1);
                }


                if (tipousuario == 'R') {
                    if (VisitaEntity.kminicial.toString().trim() === "" || VisitaEntity.kminicial.toString().trim() === "0") {
                        visitasAddValidacao(9, "KM Inicial", "Informe o km inicial do veículo")

                        setFormError(1);
                    }
                    if (VisitaEntity.kmfinal.toString().trim() === "" || VisitaEntity.kmfinal.toString().trim() === "0") {
                        visitasAddValidacao(10, "KM Final", "Informe o km final do veículo")

                        setFormError(1);
                    }
                }

                if (!dropRamo && dropRamo.length < 2) {
                    visitasAddValidacao(11, "Ramo", "Informe o ramo de atividade do cliente");
                    setFormError(1);
                }


                whatsapp.map((item) => {
                    if (item.cargo == "" || item.cargo == null) {
                        visitasAddValidacao(12, "WhatsApp", "Preencha o campo Cargo");

                        setFormError(1);
                    }
                    if (item.nomewpp == "" || item.nomewpp == null) {
                        visitasAddValidacao(13, "WhatsApp", "Preencha o campo Nome");

                        setFormError(1);
                    }
                    if (item.emailwpp == "" && item.cargo !== "Sem WhatsApp" || item.emailwpp == null && item.cargo !== "Sem WhatsApp") {
                        visitasAddValidacao(13, "WhatsApp", "Preencha o campo Email ");

                        setFormError(1);
                    } else {
                        if (!isEmail(item.emailwpp) && item.cargo !== "Sem WhatsApp") {
                            visitasAddValidacao(14, "WhatsApp", "Preencha o campo Email com um email valido ");
                            setFormError(1);
                        }

                    }
                    const whatsappWithoutSpaces = item.wpp ? item.wpp.replace(/\s/g, '') : '';
                    // console.log(item)

                    if (whatsappWithoutSpaces == "" && item.cargo !== "Sem WhatsApp" || item.wpp == null && item.cargo !== "Sem WhatsApp") {
                        visitasAddValidacao(16, "WhatsApp", "Preencha o campo WhatsApp");

                        setFormError(1);
                    } else {
                        if (whatsappWithoutSpaces !== null && whatsappWithoutSpaces.length < 10 && item.cargo !== "Sem WhatsApp") {
                            visitasAddValidacao(17, "WhatsApp", 'O número de Whatsapp digitado é inválido');

                            setFormError(1);
                        }

                    }

                })


                if (isJustificativa == true) {
                    if (VisitaEntity?.justificativa?.trim() === "") {
                        visitasAddValidacao(18, "Justificativa", "Informe a justificativa relatada pelo cliente")

                        setFormError(1);
                    }
                }

                if (nomewpp != '' || emailwpp != '' || cargo != '' || wpp != '') {
                    visitasAddValidacao(19, "WhatsApp", "Adicione o contato corretamente")
                    setFormError(1);
                }

                setFormError((error) => {
                    if (error === 0) {
                        setIsLoadingSave(true)
                        let dados = {
                            codparc: visitas[0].codigoparceiro,
                            ad_codconsult: codigoConsultor,
                            ad_dtultvisita: startDate != null ? moment(startDate).format("DD/MM/YYYY") : null,
                            ad_treinamentos: chkTreinamentos
                            , ad_fechou: chkFechou
                            , ad_financeiro: chkFinanceiro
                            , ad_financeiromsg: chkFinanceiroMSG
                            , ad_whatsapp: whatsapp
                            , dropRamo: dropRamo
                            , dropTrabalha: dropTrabalha
                            , dropPretende: dropPretende
                            , dropVendedores: dropVendedores.toString()
                            , latitude: coordsUsuario.latitude
                            , longitude: coordsUsuario.longitude
                            , distancia: Number(distancia)
                        };

                        visitasSaveAction(dados, VisitaEntity)


                    }

                });

            })

        }






    }



    const cargos = [
        { text: '', value: '' },
        { text: 'WhatsApp único', value: 'WhatsApp único' },
        { text: 'Contato Programado', value: 'Contato Programado' },
        { text: 'Controle de Entregas', value: 'Controle de Entregas' }
    ];
    //-------------joao2
    const checkBoxSemWhatsApp = (e) => {
        setSemwpp(e)
        if (e == "S") {
            const jsonData = {
                nomewpp: visitas[0].nomeparceiro,
                emailwpp: '',
                cargo: 'Sem WhatsApp',
                wpp: '',
                codcontato: whatsapp.length + 150,
                codparc: visitas[0].codigoparceiro
            };
            visitasWppAddAction(jsonData);
        } else {
            removeChecked()
        }
    };


    const handleInputSaveWpp = () => {

        //Remove os espaços da maskara do campo do whatsapp


        const whatsappWithoutSpaces = wpp ? wpp.replace(/\s/g, '') : '';


        // Verifica se os campos estão preenchidos
        const setErronomewpp = nomewpp === "";
        const setErroemailwpp = !emailwpp || !isEmail(emailwpp);
        const setErrocargo = !cargo;
        const setErrowhatsapp = !wpp || whatsappWithoutSpaces.length < 10;

        // Atualiza os estados de erro
        setSetErronomewpp(true);
        setSetErroemailwpp(true);
        setSetErrocargo(true);
        setSetErrowhatsapp(true);

        // Verifica se algum campo estava inválido anteriormente e foi corrigido
        const isAnyFieldCorrected = !setErronomewpp || !setErroemailwpp || !setErrocargo || !setErrowhatsapp;

        if (isAnyFieldCorrected) {
            // Se algum campo inválido foi corrigido, redefine o estado de erro correspondente como falso
            setSetErronomewpp(setErronomewpp);
            setSetErroemailwpp(setErroemailwpp);
            setSetErrocargo(setErrocargo);
            setSetErrowhatsapp(setErrowhatsapp);
        }

        // Verifica se todos os campos são válidos antes de adicionar a visita
        if (!setErronomewpp && !setErroemailwpp && !setErrocargo && !setErrowhatsapp) {
            const jsonData = {
                nomewpp,
                emailwpp,
                cargo,
                wpp: wpp,
                codcontato: whatsapp.length + 150,
                codparc: visitas[0].codigoparceiro
            };
            visitasWppAddAction(jsonData);
            setNomewpp('');
            setEmailwpp('');
            setCargo('');
            setWpp('');
            // this.setState({ swpp: '' });
        }
    };


    const handleInputRemoveWpp = (codcontato) => {
        // console.log(codcontato)
        visitasWppRemoveAction(codcontato)

    };

    const removeChecked = () => {
        if (semwpp == "N") {
            {
                whatsapp
                    .filter(contact => contact.cargo == "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                    .map((contact, index) => (
                        handleInputRemoveWpp(contact.codcontato)
                    ))
            }
        }
    }


    // jao
    let dias = VisitaEntity?.dias_sem_comprar;
    let semwppcad = whatsapp?.length
    if (dias > 90 && dias !== '' || semwppcad == 0 && semwppcad != '' || semwppcad == 0 || dias == null) {
        justificativaAction(true)
    } else {
        justificativaAction(false)
    }


    let clienteNovo = false// true = novo/ false = velho
    if (VisitaEntity?.dias_de_cadastro <= 90) {
        clienteNovo = true
    }

    let color = ''
    let clientedivida = VisitaEntity?.inadimplente
    let diasSemCtt = parseFloat(VisitaEntity?.dias_sem_cttprog)
    let diasSemCttsugs = VisitaEntity?.dias_sem_cttprog
    let justificativalabel = ''
    let bloquear = visitas?.[0]?.bloquear

    if (dias <= 60 && semwppcad == 0 && dias != null) {
        justificativalabel = 'Digite a justificativa pela qual o cliente não tem WhatsApp cadastrado'

    } else if (dias > 60 && semwppcad == 0 || dias == null && semwppcad == 0) {
        justificativalabel = 'Digite a justificativa pela qual o cliente não está comprando e não tem WhatsApp cadastrado'

    } else if (dias > 60 && semwppcad > 0 || dias == null) {
        justificativalabel = 'Digite a justificativa pela qual o cliente não está comprando'
    }

    // console.log(this.props.VisitaEntity.codigparc !== undefined)
    return (
        <>

            <BrowserView>

                <div>
                    {initialSearch && locPermitida === null || locPermitida === true ? (
                        <div className="ui input icon loading">


                            <DebounceInput
                                minLength={2}
                                debounceTimeout={4000}
                                onChange={event => {
                                    handleSearchQuery(event.target.value);
                                    justificativaAction(false);
                                }} maxLength={9}
                                placeholder="Código do Parceiro..."
                                value={searchQuery !== '' ? searchQuery : codigparc == 0 && searchQuery !== undefined ? '' : codigparc}
                            />


                            {loading || isLoadingSrch ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}

                        </div>
                    ) : null
                    }

                    <LogScreen logData={logScreen} />
                    <br />

                    {
                        (visitas.length > 0 && visitas?.[0]?.ativo == "S" ?
                            (


                                <Segment secondary raised>
                                    <Label as='a' color='red' ribbon>
                                        Parceiro - {visitas[0].codigoparceiro}
                                    </Label>

                                    <Form>
                                        {/*  jao */}
                                        <Form.Group widths='equal' color='gray'>
                                            <div className="field" ><label>Cliente</label>
                                                <div className="ui fluid input" style={{ marginBottom: '10px' }}>{visitas[0].nomeparceiro}</div>
                                                <div style={{ backgroundColor: '#fffaf3', padding: '10px', borderRadius: '5px', border: '1px solid #794b02' }}>
                                                    {bloquear == 'S' ? (<div className="ui fluid input" style={{ color: 'red' }}><b>Parceiro Fechado!</b></div>) : ''}
                                                    {clienteNovo === true && dias == null ? (
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                    )
                                                        : clienteNovo === true && dias < 90 ? (
                                                            <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                        ) : clienteNovo === false && dias == null ? (
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                        ) : ''}
                                                    {dias < 60 && dias != null ? (
                                                        <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                    ) : dias > 60 && dias != null ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                    ) : null}
                                                    {clientedivida > 0 ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Inadimplência de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(clientedivida)}</div>
                                                    ) : ''}
                                                    {diasSemCtt >= 60 ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>{diasSemCtt} dias sem receber Contato Programado</div>
                                                            <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                        </>
                                                    ) : ''}
                                                    {diasSemCttsugs == null && clienteNovo === false || diasSemCttsugs == '' && clienteNovo === false ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red' }}><b>Nunca recebeu Contato Programado!</b></div>
                                                            <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                        </>
                                                    ) : ''}
                                                    {semwppcad == 0 ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>Sem WhatsApp cadastrado!</div>
                                                        </>
                                                    ) : ''}
                                                    {distancia > 10 ? (
                                                        <>
                                                            <div className="ui fluid input" style={{ color: 'blue', marginBottom: '2px' }}>Você está a {Number(distancia).toFixed(2)}km deste cliente</div>
                                                        </>
                                                    ) : ''}

                                                </div>

                                            </div>


                                            <div className="field"><label>Consultor Externo</label>
                                                <div className="ui fluid input">{visitas[0].codigoconsultor} - {(visitas[0].codigoconsultor == 0 ? 'Sem consultor' : visitas[0].nomeconsultor)}</div>
                                                <If test={tipousuario == 'E'}>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Não alterar o consultor'
                                                            name='chknaoalteraconsultor'
                                                            value='S'
                                                            checked={VisitaEntity.naoalteraconsultor === 'S'}
                                                            onChange={handleChangeChknaoalteraconsultor}
                                                        />
                                                    </Form.Field>
                                                </If>
                                            </div>

                                            <div className="field"><label>Representante</label>
                                                <div className="ui fluid input">{visitas[0].codigorepresentante} - {(visitas[0].codigorepresentante == 0 ? 'Sem representante' : visitas[0].nomerepresentante)}</div>
                                            </div>


                                            <div className="field"><label>Data da Visita</label>

                                                <div>

                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleChangeDate}
                                                        onSelect={handleChangeDate}
                                                        isClearable={true}
                                                        dateFormat="dd/MM/yyyy"
                                                        todayButton={"Hoje"}
                                                    />
                                                </div>
                                                <div className="ui fluid input">últ. visita: {(visitas?.[0].dataultimavisita != null ? moment.utc(visitas?.[0].dataultimavisita).format("DD/MM/YYYY") : visitas?.[0].dataultimavisita != null ? visitas?.[0].dataultimavisita : "nenhuma")}</div>
                                            </div>

                                            <div className="field"><label>Campo Sigiloso</label>
                                                <Form.Field control={TextArea} label=''
                                                    id='sigiloso'
                                                    name='sigiloso'
                                                    placeholder='Essa mensagem não estará disponível para o vendedor...'
                                                    onChange={handleForm}
                                                    maxLength={2000}
                                                />
                                            </div>




                                        </Form.Group>

                                        <Form.Group widths='equal'>
                                            <div className="field">

                                                {
                                                    (loading === false ? (
                                                        <Form.Input name="email" fluid label="E-mail" placeholder="E-mail" value={VisitaEntity.email}
                                                            onChange={handleForm}
                                                            maxLength={50}
                                                        />


                                                    ) : (<div></div>)
                                                    )}

                                            </div>

                                            <div className="field">
                                                <label>Telefone Principal:</label>
                                                <InputMask mask="99 999999999" maskChar=" " name="telefoneprincipal" placeholder="Telefone Principal"
                                                    value={VisitaEntity.telefoneprincipal}
                                                    onChange={handleForm}
                                                />
                                            </div>

                                            <div className="field">
                                                <label>Telefone Secundário:</label>
                                                <InputMask
                                                    id="telefonesecundario"
                                                    mask="99 999999999"
                                                    maskChar=" "
                                                    name="telefonesecundario"
                                                    value={VisitaEntity.telefonesecundario || ""} // Se for null, passa uma string vazia
                                                    onChange={handleForm}
                                                />


                                            </div >
                                            <If test={tipousuario == 'E'}>
                                                <div className="field">
                                                    <label>KM atual do veículo</label>
                                                    <div>
                                                        <InputMask name="km" placeholder="KM atual"
                                                            onChange={handleForm}
                                                            mask='999999999'
                                                            maskChar={null}
                                                            style={{ width: "150px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </If>

                                            <If test={tipousuario == 'R'}>
                                                <div className="field">
                                                    <label>KM inicial</label>
                                                    <div>
                                                        <InputMask name="kminicial" placeholder="KM inicial"
                                                            onChange={handleForm}
                                                            mask='999999999'
                                                            maskChar={null}
                                                        // maxLength={10}
                                                        //style={{ width: "100px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label>KM final</label>
                                                    <div>
                                                        <InputMask name="kmfinal" placeholder="KM final"
                                                            onChange={handleForm}
                                                            mask='999999999'
                                                            maskChar={null}
                                                        // maxLength={10}
                                                        //style={{ width: "100px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </If>




                                        </Form.Group>

                                        {/* --------------------------------joao------------------------------------- */}
                                        <Form.Field> <label><b>Contatos WhatsApp</b></label></Form.Field>
                                        <Form.Group widths='equal' style={{ backgroundColor: '#e6e6e6', padding: '10px', borderRadius: '5px' }}>
                                            <Form.Field error={setErronomewpp}>
                                                {/* <div></div> */}
                                                <InputMask
                                                    name="nomewpp"
                                                    // fluid
                                                    placeholder="Nome"
                                                    onChange={(e) => setNomewpp(e.target.value)}
                                                    value={nomewpp}
                                                />
                                            </Form.Field>

                                            <Form.Field error={setErrocargo}>
                                                <Dropdown
                                                    options={cargos}
                                                    selection
                                                    placeholder='Cargo'
                                                    name='cargo'
                                                    fluid
                                                    onChange={(e, { value }) => setCargo(value)}
                                                    value={cargo}
                                                />
                                            </Form.Field>

                                            <Form.Field error={setErroemailwpp}>
                                                <InputMask
                                                    name="emailwpp"
                                                    // fluid
                                                    placeholder="E-mail"
                                                    onChange={(e) => setEmailwpp(e.target.value)}
                                                    value={emailwpp}
                                                />
                                            </Form.Field>

                                            <Form.Field error={setErrowhatsapp}>
                                                <PatternFormat icon='hashtag'
                                                    name="wpp"
                                                    // fluid
                                                    customInput={Form.Field}
                                                    control='input'
                                                    format="## ##### ####"
                                                    placeholder='Whatsapp 99 99999 9999'
                                                    onChange={(e) => setWpp(e.target.value)}
                                                    value={wpp}
                                                //onChange={e => setDocumentNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                                // labelPosition='right corner'
                                                />
                                            </Form.Field>
                                            <Button icon positive onClick={() => handleInputSaveWpp()} size='mini' basic>
                                                <Icon name='plus' />
                                            </Button>
                                        </Form.Group>
                                        <Form.Field>

                                            <Checkbox
                                                label='Parceiro sem WhatsApp'
                                                name='semwpp'
                                                checked={semwpp === 'S'}
                                                onChange={(e, data) => checkBoxSemWhatsApp(data.checked ? 'S' : 'N')}
                                                value={semwpp}
                                            />

                                        </Form.Field>

                                        {/* --------------------------------------------------------------------- */}
                                        <hr></hr>
                                        {whatsapp
                                            .filter(contact => contact.cargo !== "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                                            .map((contact, index) => (
                                                <Form.Group widths='equal' key={index}>
                                                    <React.Fragment>
                                                        <Form.Field>
                                                            <InputMask
                                                                name="nomewpp"
                                                                // fluid
                                                                placeholder="Nome"
                                                                onChange={handleForm}
                                                                // maxLength={50}
                                                                value={contact.nomewpp}
                                                                codcontato={contact.codcontato}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Dropdown
                                                                name="cargo"
                                                                id="cargo"
                                                                // defaultValue={contact.cargo}
                                                                options={cargos}
                                                                selection
                                                                placeholder='Cargo'
                                                                onChange={(e, { value }) => handleForm({ target: { name: 'cargo', value, codcontato: contact.codcontato } })}
                                                                fluid
                                                                codcontato={contact.codcontato}
                                                                value={contact.cargo}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <InputMask
                                                                name="emailwpp"
                                                                // fluid
                                                                placeholder="E-mail"
                                                                onChange={handleForm}
                                                                // maxLength={50}
                                                                value={contact.emailwpp}
                                                                codcontato={contact.codcontato}
                                                            />
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <PatternFormat
                                                                icon='hashtag'
                                                                // iconPosition='left'
                                                                name="wpp"
                                                                // fluid
                                                                customInput={Form.Field}
                                                                control='input'
                                                                format="## ##### ####"
                                                                placeholder='Whatsapp 99 99999 9999'
                                                                onChange={handleForm}
                                                                value={contact.wpp}
                                                                codcontato={contact.codcontato}
                                                            />
                                                        </Form.Field>
                                                        <Button icon negative onClick={() => handleInputRemoveWpp(contact.codcontato)}>
                                                            <Icon name='x' />
                                                        </Button>
                                                    </React.Fragment>
                                                </Form.Group>
                                            ))}



                                        {/* --------------------------------------------------------------------- */}
                                        <Form.Group widths='equal'>
                                            {
                                                (ramoatividade.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Ramo de atividade:</label>
                                                        <div>
                                                            <Dropdown
                                                                options={ramoatividade}
                                                                selection
                                                                placeholder='Ramo de atividade'
                                                                name='ramoatividade'
                                                                multiple
                                                                fluid
                                                                defaultValue={ramoatividadeSelecionado}
                                                                onChange={handleRamoAtividade}

                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}

                                            {
                                                (produtostrabalha.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Produtos que trabalha:</label>
                                                        <div>
                                                            <Dropdown
                                                                //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                options={produtostrabalha}
                                                                selection
                                                                //value={pedido.ad_tracknentid}
                                                                //pedido={pedido}
                                                                placeholder='Produtos que trabalha'
                                                                name='produtostrabalha'
                                                                multiple
                                                                fluid
                                                                defaultValue={produtostrabalhaSelecionado}
                                                                onChange={handleProdutosTrabalha}
                                                            //ref={this.combo}
                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}

                                            {
                                                (produtospretende.length > 0 ? (
                                                    //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                    <div className="field">
                                                        <label>Produtos que pretende trabalhar:</label>
                                                        <div>
                                                            <Dropdown
                                                                //onChange={this.onChangePedidoMotivo.bind(this)}
                                                                options={produtospretende}
                                                                selection
                                                                //value={pedido.ad_tracknentid}
                                                                //pedido={pedido}
                                                                placeholder='Produtos que pretende trabalhar'
                                                                name='produtospretende'
                                                                multiple
                                                                fluid
                                                                defaultValue={produtospretendeSelecionado}
                                                                onChange={handleProdutosPretende}
                                                            //ref={this.combo}
                                                            />
                                                        </div>

                                                    </div>
                                                ) : (<div></div>)
                                                )}
                                        </Form.Group>


                                        <Form.Group widths='equal'>
                                            <div className="field"><label>Financeiro:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Reavaliação de crédito'
                                                            name='chkfinreavaliacaodecredito'
                                                            value='S'
                                                            checked={VisitaEntity.finreavaliacaodecredito === 'S'}
                                                            onChange={handleChangeChkfinreavaliacaodecredito}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Pagar dívidas'
                                                            name='chkfinpagardividas'
                                                            value='S'
                                                            checked={VisitaEntity.finpagardividas === 'S'}
                                                            onChange={handleChangeChkfinpagardividas}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Adquirir ADSite'
                                                            name='chkIntADSite'
                                                            value='S'
                                                            checked={VisitaEntity.interesseadsite === 'S'}
                                                            onChange={handleChangeChkIntADSite}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Parceiro fechou ou não existe mais?'
                                                            name='chkFechou'
                                                            value='S'
                                                            checked={chkFechou === 'S'}
                                                            onChange={handleChangeChkFechou}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Enviar mensagem para o financeiro'
                                                            name='chkFinanceiro'
                                                            value='S'
                                                            checked={chkFinanceiro === 'S'}
                                                            onChange={handleChangeChkFinanceiro}
                                                        />
                                                    </Form.Field>
                                                    <div className="field">
                                                        <Form.Field control={TextArea} label=''
                                                            placeholder='Digite a mensagem para o depto. financeiro...'
                                                            onChange={handleChangeMsgFinanceiro}
                                                            maxLength={2000}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field"><label>Comercial:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Falta contato/retorno'
                                                            name='chkComContato'
                                                            value='S'
                                                            checked={VisitaEntity.comfaltacontato === 'S'}
                                                            onChange={handleChangeChkComContato}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Interesse em Display'
                                                            name='chkIntDisplay'
                                                            value='S'
                                                            checked={VisitaEntity.interessedisplay === 'S'}
                                                            onChange={handleChangeChkIntDisplay}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Interesse em Treinamentos'
                                                            name='chkIntTreinamentos'
                                                            value='S'
                                                            checked={VisitaEntity.interessetreinamentos === 'S'}
                                                            onChange={handleChangeChkIntTreinamentos}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Mostrou mala de amostras?'
                                                            name='mostrouamost'
                                                            value='S'
                                                            checked={VisitaEntity.mostrouamost === 'S'}
                                                            onChange={handleChangeChkAmostras}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Apresentou o B2B?'
                                                            name='mostrouamost'
                                                            value='S'
                                                            checked={VisitaEntity.mostroub2b === 'S'}
                                                            onChange={handleChangeChkB2B}
                                                        />
                                                    </Form.Field>
                                                    <div style={{ color: 'red' }}>

                                                        {`                                                
                                    Classificação atual: ${visitas[0].classificacaofin}
                                    Tipo: ${visitas[0].tipoclassificacaofin} | Data: ${moment(visitas[0].dtclassificacaofin).utc().format("DD/MM/YYYY")}
                                    Upgrade por ${visitas[0].valormeses} meses?
                                    Em ${visitas[0].mesestotal} meses para A: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valormina)} e para B: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valorminb)}, com compras em 3 meses diferentes.
                                `}

                                                        <If test={visitas[0].classificacaofin != 'AA' && visitas[0].classificacaofin != 'D' && visitas[0].classificacaofin != 'A'}>

                                                            <Dropdown
                                                                options={
                                                                    (visitas[0].classificacaofin == 'C'
                                                                        ? [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                            , { key: 'B', text: 'B', value: 'B' }]
                                                                        : [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                        ])
                                                                }
                                                                selection
                                                                placeholder='Classificação Financeira'
                                                                name='classfin'
                                                                fluid
                                                                defaultValue={visitas[0]?.classificacaofin?.toString()}
                                                                onChange={handleClassificacaoFin}
                                                            />
                                                        </If>


                                                    </div>
                                                    <div className="field">
                                                        <Form.Field control={TextArea} label=''
                                                            id='msgpesquisamercado'
                                                            name='msgpesquisamercado'
                                                            placeholder='Informe concorrente,Produto,Preço...'
                                                            onChange={handleForm}
                                                            maxLength={2000}
                                                            rows='5'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <div className="field"> <label>Vendedor:</label>
                                                    <div>
                                                        <Dropdown
                                                            options={vendedores}
                                                            selection
                                                            placeholder='Vendedores'
                                                            name='vendedor'
                                                            fluid
                                                            defaultValue={visitas[0].codigovendedor.toString()}
                                                            onChange={handleVendedores}
                                                        />
                                                    </div>
                                                    <div className="field">
                                                        <Form.Field control={TextArea} label=''
                                                            id='msgvendedor'
                                                            name='msgvendedor'
                                                            placeholder='Digite a mensagem para o vendedor...'
                                                            onChange={handleForm}
                                                            maxLength={2000}
                                                        />
                                                    </div>
                                                </div>
                                                <label>Logística:</label>
                                                <div>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Teve Material Danificado'
                                                            name='chklogmaterialdanificado'
                                                            value='S'
                                                            checked={VisitaEntity.logmaterialdanificado === 'S'}
                                                            onChange={handleChangeChklogmaterialdanificado}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Checkbox
                                                            label='Não entrega em Obras/Escadas'
                                                            name='chklogentregaobrasobeescada'
                                                            value='S'
                                                            checked={VisitaEntity.logentregaobrasobeescada === 'S'}
                                                            onChange={handleChangeChklogentregaobrasobeescada}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>

                                            <div className="field">

                                                <div className="field">
                                                    <Form.Field control={TextArea} label='Relato'
                                                        id='conversa'
                                                        name='conversa'
                                                        placeholder='Digite a ação/conversa realizada com o cliente...'
                                                        onChange={handleForm}
                                                        maxLength={4000}
                                                        rows='9'
                                                    />
                                                </div>

                                            </div>
                                            {isJustificativa == true ? (
                                                <div className="field">
                                                    <Form.Field
                                                        control={TextArea}
                                                        label='Justificativa'
                                                        id='justificativa'
                                                        name='justificativa'
                                                        placeholder={justificativalabel}
                                                        onChange={handleForm}
                                                        maxLength={4000}
                                                        rows='9'
                                                    />
                                                </div>
                                            ) : ''}

                                        </Form.Group>





                                        <Form.Group widths='equal'>
                                            <div className="field">
                                                <div>



                                                </div>
                                            </div>


                                            <div className="field"><label></label>
                                                <div className="ui fluid input"></div>
                                            </div>
                                        </Form.Group>



                                        {
                                            (validacao.length > 0 ? (
                                                <Message negative>
                                                    {validacao.map(valida => (
                                                        <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                                    ))}

                                                </Message>
                                            ) : (
                                                <div></div>
                                            ))
                                        }

                                        <Form.Button primary
                                            loading={isLoadingSave}
                                            onClick={salvarVisita}
                                        >Salvar</Form.Button>









                                    </Form>
                                    <br />

                                    {
                                        //20/12/2018 - histórico cancelado pois foi alterado a proc e precisa ajustar o front-end p exibir o novo formato
                                        //<Historico data={historicodata}/>
                                    }





                                </Segment>


                            ) : (
                                <div>

                                    {
                                        (visitas?.[0]?.ativo == "N" ? (
                                            <Message warning>
                                                <Message.Header>Parceiro Inativo</Message.Header>
                                                <p>O parceiro <b>{searchQuery}</b> está inativo no Mitra. Por favor, avise o vendedor ou departamento de cadastro.</p>
                                            </Message>
                                        ) : initialSearch && locPermitida === null || initialSearch && locPermitida === true ? (
                                            <Message info>
                                                <Message.Header>Busque um parceiro</Message.Header>
                                                <p>Digite acima o código do parceiro que visitou. <br />Após salvar, o parceiro será vinculado automaticamente à você.</p>
                                            </Message>

                                        ) : locPermitida === false ? (
                                            <div>
                                                <Message error>
                                                    <Message.Header>Localização indisponível</Message.Header>
                                                    <p>Ops! Parece que a localização do seu dispositivo não está disponível</p>
                                                    <p>Caso tenha bloqueado a permissão de localização, acesse o link: <a style={{ color: 'red' }} href="/localizacao-desktop.png" target="_blank" rel="noopener noreferrer">
                                                        Tutorial
                                                    </a></p>
                                                    <p>Se apenas fechou o popup de localização clique no botão:</p>
                                                    <Button color='red' onClick={handleGetLocation}>Clique aqui para permitir a localização</Button>
                                                </Message>
                                            </div>

                                        ) : (
                                            <Message warning>
                                                <Message.Header>Parceiro não encontrado</Message.Header>
                                                <p>Não encontramos o código de parceiro <b>{searchQuery}</b>. Por favor digite um código de parceiro ativo</p>
                                            </Message>
                                        ))
                                    }

                                </div>
                            ))
                    }

                </div >
            </BrowserView >
            <MobileView>
                <div>
                    {initialSearch && locPermitida === null || locPermitida === true ? (
                        <div className="ui input icon loading">

                            <DebounceInput
                                minLength={2}
                                debounceTimeout={4000}
                                onChange={event => {
                                    handleSearchQuery(event.target.value);
                                    justificativaAction(false);
                                }}
                                maxLength={9}
                                placeholder="Código do Parceiro..."
                                value={searchQuery !== '' ? searchQuery : codigparc == 0 && searchQuery !== undefined ? '' : codigparc}

                            />


                            {loading || isLoadingSrch ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}

                        </div>
                    ) : null
                    }
                    <LogScreen logData={logScreen} />
                    <br />

                    {
                        (visitas.length > 0 && visitas?.[0]?.ativo == "S" ? (


                            <Segment secondary raised>
                                <Label as='a' color='red' ribbon>
                                    Parceiro - {visitas[0].codigoparceiro}
                                </Label>

                                <Form>
                                    <Form.Group widths='equal' color='gray'>
                                        <div className="field" ><label>Cliente</label>
                                            <div className="ui fluid input" style={{ marginBottom: '10px' }}>{visitas[0].nomeparceiro}</div>
                                            <div style={{ backgroundColor: '#fffaf3', padding: '10px', borderRadius: '5px', border: '1px solid #794b02' }}>
                                                {bloquear == 'S' ? (<div className="ui fluid input" style={{ color: 'red' }}><b>Parceiro Fechado!</b></div>) : ''}
                                                {clienteNovo === true && dias == null ? (
                                                    <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                ) : clienteNovo === true && dias < 90 ? (
                                                    <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                ) : clienteNovo === false && dias == null ? (
                                                    <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                ) : ''}
                                                {dias < 60 && dias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                ) : dias > 60 && dias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{dias} dias sem comprar!</div>
                                                ) : null}
                                                {clientedivida > 0 ? (
                                                    <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Inadimplência de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(clientedivida)}</div>
                                                ) : ''}
                                                {diasSemCtt >= 60 ? (
                                                    <>
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>{diasSemCtt} dias sem receber Contato Programado</div>
                                                        <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                    </>
                                                ) : ''}
                                                {diasSemCttsugs == null && clienteNovo === false || diasSemCttsugs == '' && clienteNovo === false ? (
                                                    <>
                                                        <div className="ui fluid input" style={{ color: 'red' }}><b>Nunca recebeu Contato Programado!</b></div>
                                                        <div className="ui fluid input" style={{ color: '#000000' }}>Sugestão: entrar em contato com o vendedor ou atribuir outro a este parceiro.</div>
                                                    </>
                                                ) : ''}
                                                {semwppcad == 0 ? (
                                                    <>
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '2px' }}>Sem WhatsApp cadastrado!</div>
                                                    </>
                                                ) : ''}
                                                {distancia > 10 ? (
                                                    <>
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '2px' }}>Você está a {Number(distancia).toFixed(2)}km deste cliente</div>
                                                    </>
                                                ) : ''}

                                            </div>
                                        </div>


                                        <div className="field"><label>Consultor Externo</label>
                                            <div className="ui fluid input">{visitas[0].codigoconsultor} - {(visitas[0].codigoconsultor == 0 ? 'Sem consultor' : visitas[0].nomeconsultor)}</div>
                                            <If test={tipousuario == 'E'}>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Não alterar o consultor'
                                                        name='chknaoalteraconsultor'
                                                        value='S'
                                                        checked={VisitaEntity.naoalteraconsultor === 'S'}
                                                        onChange={handleChangeChknaoalteraconsultor}
                                                    />
                                                </Form.Field>
                                            </If>
                                        </div>

                                        <div className="field"><label>Representante</label>
                                            <div className="ui fluid input">{visitas[0].codigorepresentante} - {(visitas[0].codigorepresentante == 0 ? 'Sem representante' : visitas[0].nomerepresentante)}</div>
                                        </div>


                                        <div className="field"><label>Data da Visita</label>

                                            <div>

                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={handleChangeDate}
                                                    onSelect={handleChangeDate}
                                                    isClearable={true}
                                                    dateFormat="dd/MM/yyyy"
                                                    todayButton={"Hoje"}
                                                />
                                            </div>
                                            <div className="ui fluid input">últ. visita: {(visitas?.[0].dataultimavisita != null ? moment.utc(visitas?.[0].dataultimavisita).format("DD/MM/YYYY") : visitas?.[0].dataultimavisita != null ? visitas?.[0].dataultimavisita : "nenhuma")}</div>
                                        </div>

                                        <div className="field"><label>Campo Sigiloso</label>
                                            <Form.Field control={TextArea} label=''
                                                id='sigiloso'
                                                name='sigiloso'
                                                placeholder='Essa mensagem não estará disponível para o vendedor...'
                                                onChange={handleForm}
                                                maxLength={2000}
                                            />
                                        </div>




                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        <div className="field">

                                            {
                                                (loading === false ? (
                                                    <Form.Input name="email" fluid label="E-mail" placeholder="E-mail" value={VisitaEntity.email}
                                                        //error={this.state.emailError}
                                                        //onChange={this.props.visitasHandleChangeAction}
                                                        onChange={handleForm}
                                                        maxLength={50}
                                                    />


                                                ) : (<div></div>)
                                                )}

                                        </div>

                                        <div className="field">
                                            <label>Telefone Principal:</label>
                                            <InputMask mask="99 999999999" maskChar=" " name="telefoneprincipal" placeholder="Telefone Principal"
                                                value={VisitaEntity.telefoneprincipal}
                                                onChange={handleForm}
                                            />
                                        </div>

                                        <div className="field">
                                            <label>Telefone Secundário:</label>
                                            <InputMask
                                                id="telefonesecundario"
                                                mask="99 999999999"
                                                maskChar=" "
                                                name="telefonesecundario"
                                                value={VisitaEntity.telefonesecundario || ""} // Se for null, passa uma string vazia
                                                onChange={handleForm}
                                            />


                                        </div>


                                        <div className="field"> <label>Vendedor:</label>
                                            <div>
                                                <Dropdown
                                                    options={vendedores}
                                                    selection
                                                    placeholder='Vendedores'
                                                    name='vendedor'
                                                    fluid
                                                    defaultValue={visitas[0].codigovendedor.toString()}
                                                    onChange={handleVendedores}
                                                />
                                            </div>
                                            <div className="field">
                                                <Form.Field control={TextArea} label=''
                                                    id='msgvendedor'
                                                    name='msgvendedor'
                                                    placeholder='Digite a mensagem para o vendedor...'
                                                    onChange={handleForm}
                                                    maxLength={2000}
                                                />
                                            </div>
                                        </div>

                                        <If test={tipousuario == 'R'}>
                                            <div className="field">
                                                <label>KM inicial</label>
                                                <div>
                                                    <InputMask name="kminicial" placeholder="KM inicial"
                                                        onChange={handleForm}
                                                        mask='999999999'
                                                        maskChar={null}
                                                    // maxLength={10}
                                                    //style={{ width: "100px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="field">
                                                <label>KM final</label>
                                                <div>
                                                    <InputMask name="kmfinal" placeholder="KM final"
                                                        onChange={handleForm}
                                                        mask='999999999'
                                                        maskChar={null}
                                                    // maxLength={10}
                                                    //style={{ width: "100px" }}
                                                    />
                                                </div>
                                            </div>
                                        </If>




                                    </Form.Group>

                                    {/* --------------------------------joao------------------------------------- */}
                                    <Form.Field> <label><b>Contatos WhatsApp</b></label></Form.Field>
                                    <Form.Group widths='equal' style={{ backgroundColor: '#e6e6e6', padding: '10px', borderRadius: '5px' }}>
                                        <Form.Field error={setErronomewpp}>
                                            {/* <div></div> */}
                                            <InputMask
                                                name="nomewpp"
                                                // fluid
                                                placeholder="Nome"
                                                onChange={(e) => setNomewpp(e.target.value)}
                                                value={nomewpp}
                                            />
                                        </Form.Field>

                                        <Form.Field error={setErrocargo}>
                                            <Dropdown
                                                options={cargos}
                                                selection
                                                placeholder='Cargo'
                                                name='cargo'
                                                fluid
                                                onChange={(e, { value }) => setCargo(value)}
                                                value={cargo}
                                            />
                                        </Form.Field>

                                        <Form.Field error={setErroemailwpp}>
                                            <InputMask
                                                name="emailwpp"
                                                // fluid
                                                placeholder="E-mail"
                                                onChange={(e) => setEmailwpp(e.target.value)}
                                                value={emailwpp}
                                            />
                                        </Form.Field>

                                        <Form.Field error={setErrowhatsapp}>
                                            <PatternFormat icon='hashtag'
                                                name="wpp"
                                                // fluid
                                                customInput={Form.Field}
                                                control='input'
                                                format="## ##### ####"
                                                placeholder='Whatsapp 99 99999 9999'
                                                onChange={(e) => setWpp(e.target.value)}
                                                value={wpp}
                                            //onChange={e => setDocumentNumber(e.target.value.replace(/[^0-9]/g, ''))}
                                            // labelPosition='right corner'
                                            />

                                        </Form.Field>
                                        <Button icon positive fluid onClick={() => handleInputSaveWpp()} size='mini' basic>
                                            Adicionar Contato
                                        </Button>
                                        <hr></hr>
                                        <Form.Field>

                                            <Checkbox
                                                label='Parceiro sem WhatsApp'
                                                name='semwpp'
                                                checked={semwpp === 'S'}
                                                onChange={(e, data) => checkBoxSemWhatsApp(data.checked ? 'S' : 'N')}
                                                value={semwpp}
                                            />

                                        </Form.Field>

                                    </Form.Group>

                                    {/* --------------------------------------------------------------------- */}
                                    <hr key='space' />

                                    <div>

                                        <Card.Group>

                                            {whatsapp
                                                .filter(contact => contact.cargo !== "Sem WhatsApp") // Filtrar os contatos que não têm o cargo "Sem WhatsApp"
                                                .map((contact, index) => (
                                                    <Card fluid key={index}>
                                                        <Card.Content>
                                                            <Card.Description>

                                                                <Form.Group widths='equal'>
                                                                    <Form.Field>
                                                                        <InputMask
                                                                            name="nomewpp"
                                                                            // fluid
                                                                            placeholder="Nome"
                                                                            onChange={handleForm}
                                                                            // maxLength={50}
                                                                            value={contact.nomewpp}
                                                                            codcontato={contact.codcontato}
                                                                        />
                                                                    </Form.Field>
                                                                    <Form.Field>
                                                                        <Dropdown
                                                                            name="cargo"
                                                                            id="cargo"
                                                                            // defaultValue={contact.cargo}
                                                                            options={cargos}
                                                                            selection
                                                                            placeholder='Cargo'
                                                                            onChange={(e, { value }) => handleForm({ target: { name: 'cargo', value, codcontato: contact.codcontato } })}
                                                                            fluid
                                                                            codcontato={contact.codcontato}
                                                                            value={contact.cargo}
                                                                        />
                                                                    </Form.Field>

                                                                </Form.Group>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Field>
                                                                        <InputMask
                                                                            name="emailwpp"
                                                                            // fluid
                                                                            placeholder="E-mail"
                                                                            onChange={handleForm}
                                                                            // maxLength={50}
                                                                            value={contact.emailwpp}
                                                                            codcontato={contact.codcontato}
                                                                        />
                                                                    </Form.Field>

                                                                    <Form.Field>
                                                                        <PatternFormat icon='hashtag'
                                                                            name="wpp"
                                                                            // fluid
                                                                            customInput={Form.Field}
                                                                            control='input'
                                                                            format="## ##### ####"
                                                                            placeholder='Whatsapp 99 99999 9999'
                                                                            onChange={handleForm}
                                                                            value={contact.wpp}
                                                                            codcontato={contact.codcontato}

                                                                        />
                                                                    </Form.Field>

                                                                </Form.Group>


                                                            </Card.Description>
                                                        </Card.Content>
                                                        <Card.Content>

                                                            <div>
                                                                <Button basic negative fluid onClick={() => handleInputRemoveWpp(contact.codcontato)}>Excluir Contato</Button>
                                                            </div>
                                                        </Card.Content>
                                                    </Card>


                                                ))}
                                        </Card.Group>
                                    </div>





                                    {/* --------------------------------------------------------------------- */}
                                    <Form.Group widths='equal'>
                                        {
                                            (ramoatividade.length > 0 ? (
                                                //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                <div className="field">
                                                    <label>Ramo de atividade:</label>
                                                    <div>
                                                        <Dropdown
                                                            options={ramoatividade}
                                                            selection
                                                            placeholder='Ramo de atividade'
                                                            name='ramoatividade'
                                                            multiple
                                                            fluid
                                                            defaultValue={ramoatividadeSelecionado}
                                                            onChange={handleRamoAtividade}

                                                        />
                                                    </div>

                                                </div>
                                            ) : (<div></div>)
                                            )}

                                        {
                                            (produtostrabalha.length > 0 ? (
                                                //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                <div className="field">
                                                    <label>Produtos que trabalha:</label>
                                                    <div>
                                                        <Dropdown
                                                            //onChange={this.onChangePedidoMotivo.bind(this)}
                                                            options={produtostrabalha}
                                                            selection
                                                            //value={pedido.ad_tracknentid}
                                                            //pedido={pedido}
                                                            placeholder='Produtos que trabalha'
                                                            name='produtostrabalha'
                                                            multiple
                                                            fluid
                                                            defaultValue={produtostrabalhaSelecionado}
                                                            onChange={handleProdutosTrabalha}
                                                        //ref={this.combo}
                                                        />
                                                    </div>

                                                </div>
                                            ) : (<div></div>)
                                            )}

                                        {
                                            (produtospretende.length > 0 ? (
                                                //<RamoAtividade data={ramoatividade} selected={ramoatividadeSelecionado} />
                                                <div className="field">
                                                    <label>Produtos que pretende trabalhar:</label>
                                                    <div>
                                                        <Dropdown
                                                            //onChange={this.onChangePedidoMotivo.bind(this)}
                                                            options={produtospretende}
                                                            selection
                                                            //value={pedido.ad_tracknentid}
                                                            //pedido={pedido}
                                                            placeholder='Produtos que pretende trabalhar'
                                                            name='produtospretende'
                                                            multiple
                                                            fluid
                                                            defaultValue={produtospretendeSelecionado}
                                                            onChange={handleProdutosPretende}
                                                        //ref={this.combo}
                                                        />
                                                    </div>

                                                </div>
                                            ) : (<div></div>)
                                            )}
                                    </Form.Group>


                                    <Form.Group widths='equal'>
                                        <div className="field"><label>Financeiro:</label>
                                            <div>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Reavaliação de crédito'
                                                        name='chkfinreavaliacaodecredito'
                                                        value='S'
                                                        checked={VisitaEntity.finreavaliacaodecredito === 'S'}
                                                        onChange={handleChangeChkfinreavaliacaodecredito}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Pagar dívidas'
                                                        name='chkfinpagardividas'
                                                        value='S'
                                                        checked={VisitaEntity.finpagardividas === 'S'}
                                                        onChange={handleChangeChkfinpagardividas}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Adquirir ADSite'
                                                        name='chkIntADSite'
                                                        value='S'
                                                        checked={VisitaEntity.interesseadsite === 'S'}
                                                        onChange={handleChangeChkIntADSite}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Parceiro fechou ou não existe mais?'
                                                        name='chkFechou'
                                                        value='S'
                                                        checked={chkFechou === 'S'}
                                                        onChange={handleChangeChkFechou}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Enviar mensagem para o financeiro'
                                                        name='chkFinanceiro'
                                                        value='S'
                                                        checked={chkFinanceiro === 'S'}
                                                        onChange={handleChangeChkFinanceiro}
                                                    />
                                                </Form.Field>
                                                <div className="field">
                                                    <Form.Field control={TextArea} label=''
                                                        placeholder='Digite a mensagem para o depto. financeiro...'
                                                        onChange={handleChangeMsgFinanceiro}
                                                        maxLength={2000}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="field"><label>Comercial:</label>
                                            <div>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Falta contato/retorno'
                                                        name='chkComContato'
                                                        value='S'
                                                        checked={VisitaEntity.comfaltacontato === 'S'}
                                                        onChange={handleChangeChkComContato}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Interesse em Display'
                                                        name='chkIntDisplay'
                                                        value='S'
                                                        checked={VisitaEntity.interessedisplay === 'S'}
                                                        onChange={handleChangeChkIntDisplay}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Interesse em Treinamentos'
                                                        name='chkIntTreinamentos'
                                                        value='S'
                                                        checked={VisitaEntity.interessetreinamentos === 'S'}
                                                        onChange={handleChangeChkIntTreinamentos}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Mostrou mala de amostras?'
                                                        name='mostrouamost'
                                                        value='S'
                                                        checked={VisitaEntity.mostrouamost === 'S'}
                                                        onChange={handleChangeChkAmostras}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Apresentou o B2B?'
                                                        name='mostrouamost'
                                                        value='S'
                                                        checked={VisitaEntity.mostroub2b === 'S'}
                                                        onChange={handleChangeChkB2B}
                                                    />
                                                </Form.Field>
                                                <div style={{ color: 'red' }}>

                                                    {`                                                
                Classificação atual: ${visitas[0].classificacaofin}
                Tipo: ${visitas[0].tipoclassificacaofin} | Data: ${moment(visitas[0].dtclassificacaofin).utc().format("DD/MM/YYYY")}
                Upgrade por ${visitas[0].valormeses} meses?
                Em ${visitas[0].mesestotal} meses para A: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valormina)} e para B: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(visitas[0].valorminb)}, com compras em 3 meses diferentes.
            `}

                                                    <If test={visitas[0].classificacaofin != 'AA' && visitas[0].classificacaofin != 'D' && visitas[0].classificacaofin != 'A'}>

                                                        <Dropdown
                                                            options={
                                                                (visitas[0].classificacaofin == 'C'
                                                                    ? [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                        , { key: 'B', text: 'B', value: 'B' }]
                                                                    : [{ key: visitas[0].classificacaofin, text: visitas[0].classificacaofin, value: visitas[0].classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                    ])
                                                            }
                                                            selection
                                                            placeholder='Classificação Financeira'
                                                            name='classfin'
                                                            fluid
                                                            defaultValue={visitas[0]?.classificacaofin?.toString()}
                                                            onChange={handleClassificacaoFin}
                                                        />
                                                    </If>


                                                </div>
                                                <div className="field">
                                                    <Form.Field control={TextArea} label=''
                                                        id='msgpesquisamercado'
                                                        name='msgpesquisamercado'
                                                        placeholder='Informe concorrente,Produto,Preço...'
                                                        onChange={handleForm}
                                                        maxLength={2000}
                                                        rows='5'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">

                                            <If test={tipousuario == 'E'}>
                                                <div className="field">

                                                    <label>KM atual do veículo</label>
                                                    <div>
                                                        <InputMask name="km" placeholder="KM atual"

                                                            onChange={handleForm}
                                                            //value={VisitaEntity.km}
                                                            mask='999999999'
                                                            maskChar={null}
                                                        // maxLength={10}
                                                        // style={{ width: "150px" }}
                                                        />



                                                    </div>
                                                </div>
                                            </If>

                                            <label>Logística:</label>
                                            <div>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Teve Material Danificado'
                                                        name='chklogmaterialdanificado'
                                                        value='S'
                                                        checked={VisitaEntity.logmaterialdanificado === 'S'}
                                                        onChange={handleChangeChklogmaterialdanificado}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        label='Não entrega em Obras/Escadas'
                                                        name='chklogentregaobrasobeescada'
                                                        value='S'
                                                        checked={VisitaEntity.logentregaobrasobeescada === 'S'}
                                                        onChange={handleChangeChklogentregaobrasobeescada}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>

                                        <div className="field">

                                            <div className="field">
                                                <Form.Field control={TextArea} label='Relato'
                                                    id='conversa'
                                                    name='conversa'
                                                    placeholder='Digite a ação/conversa realizada com o cliente...'
                                                    onChange={handleForm}
                                                    maxLength={4000}
                                                    rows='9'
                                                />
                                            </div>
                                            {isJustificativa === true ? (
                                                <div className="field">
                                                    <Form.Field
                                                        control={TextArea}
                                                        label='Justificativa'
                                                        id='justificativa'
                                                        name='justificativa'
                                                        placeholder={justificativalabel}
                                                        onChange={(e) => handleForm(e)}
                                                        maxLength={4000}
                                                        rows='9'
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                    </Form.Group>





                                    <Form.Group widths='equal'>
                                        <div className="field">
                                            <div>



                                            </div>
                                        </div>


                                        <div className="field"><label></label>
                                            <div className="ui fluid input"></div>
                                        </div>
                                    </Form.Group>



                                    {
                                        (validacao.length > 0 ? (
                                            <Message negative>
                                                {validacao.map(valida => (
                                                    <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                                ))}

                                            </Message>
                                        ) : (
                                            <div></div>
                                        ))
                                    }

                                    <Form.Button primary
                                        loading={isLoadingSave}
                                        onClick={salvarVisita}
                                    >Salvar</Form.Button>









                                </Form>
                                <br />

                                {
                                    //20/12/2018 - histórico cancelado pois foi alterado a proc e precisa ajustar o front-end p exibir o novo formato
                                    //<Historico data={historicodata}/>
                                }





                            </Segment>


                        ) : (
                            <div>

                                {
                                    (visitas?.[0]?.ativo == "N" ? (
                                        <Message warning>
                                            <Message.Header>Parceiro Inativo</Message.Header>
                                            <p>O parceiro <b>{searchQuery}</b> está inativo no Mitra. Por favor, avise o vendedor ou departamento de cadastro.</p>
                                        </Message>
                                    ) : initialSearch && locPermitida === null || initialSearch && locPermitida === true ? (
                                        <Message info>
                                            <Message.Header>Busque um parceiro</Message.Header>
                                            <p>Digite acima o código do parceiro que visitou. <br />Após salvar, o parceiro será vinculado automaticamente à você.</p>
                                        </Message>

                                    ) : locPermitida === false ? (
                                        <div>
                                            <Message error>
                                                <Message.Header>Localização indisponível</Message.Header>
                                                <p>Ops! Parece que a localização do seu dispositivo não está disponível</p>
                                                <p>Caso tenha bloqueado a permissão de localização, acesse o link: <a style={{ color: 'red' }} href="/localizacao-mobile.png" target="_blank" rel="noopener noreferrer">
                                                    Tutorial
                                                </a></p>
                                                <p>Se apenas fechou o popup de localização clique no botão:</p>
                                                <Button color='red' onClick={handleGetLocation}>Clique aqui para permitir a localização</Button>
                                            </Message>
                                        </div>

                                    ) : (
                                        <Message warning>
                                            <Message.Header>Parceiro não encontrado</Message.Header>
                                            <p>Não encontramos o código de parceiro <b>{searchQuery}</b>. Por favor digite um código de parceiro ativo</p>
                                        </Message>
                                    ))
                                }

                            </div>
                        ))
                    }

                </div>
            </MobileView >

        </>
    )
}


const mapStateToProps = state => (
    {
        visitas: state.VisitasReducer.visitas,
        isSaving: state.VisitasReducer.isSaving,
        isJustificativa: state.VisitasReducer.isJustificativa,
        logScreen: state.VisitasReducer.logScreen,
        codigoConsultor: state.LoginReducer.codigoConsultor,
        isAuthenticated: state.LoginReducer.isAuthenticated,
        tipousuario: state.LoginReducer.tipousuario,
        historicodata: state.VisitasReducer.historico,
        ramoatividade: state.VisitasReducer.ramoatividade,
        ramoatividadeSelecionado: state.VisitasReducer.ramoatividadeSelecionado,
        produtostrabalha: state.VisitasReducer.produtostrabalha,
        produtostrabalhaSelecionado: state.VisitasReducer.produtostrabalhaSelecionado,
        produtospretende: state.VisitasReducer.produtospretende,
        produtospretendeSelecionado: state.VisitasReducer.produtospretendeSelecionado,
        VisitaEntity: state.VisitasReducer.VisitaEntity,
        vendedores: state.VisitasReducer.vendedores,
        validacao: state.VisitasReducer.validacao,
        existecodparc: state.NovoClienteReducer.existecodparc,
        whatsapp: state.VisitasReducer.whatsapp,
        codigparc: state.VisitasReducer.codigparc,
        isLoadingSrch: state.VisitasReducer.isLoadingSrch

    }
);
Visitas.defaultProps = {
    ultdtvisita: ''
};
Visitas.propTypes = {
    ultdtvisita: PropTypes.string.isRequired
}

export default connect(mapStateToProps, {
    visitasListAction
    , visitasSaveAction
    , justificativaAction
    , loginValidAction
    , visitasRamosProdutosClearAction
    , visitasHandleChangeAction
    , visitasSaveExtraAction
    , visitasAddValidacao
    , visitasRemoveValidacao
    , novoClienteDocumentoAction
    , visitasWppAddAction
    , visitasWppRemoveAction
    , atualizarCampos
}
)(Visitas);






