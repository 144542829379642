import sortBy from 'sort-by';

import {
    VISITAS_LIST
    , VISITAS_SAVING
    , VISITAS_LOGSCREEN
    , VISITAS_HISTORICO_LIST
    , VISITAS_RAMOATIVIDADE_LIST
    , VISITAS_PRODUTOSTRABALHA_LIST
    , VISITAS_PRODUTOSPRETENDE_LIST
    , VISITAS_RAMOATIVIDADE_SELECIONADO_LIST
    , VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST
    , VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST
    , VISITAS_CHANGE
    , VISITAS_CHANGE_WPP
    , VISITAS_ENTITY_LOAD
    , VISITAS_VENDEDORES_LIST
    , VISITAS_VALIDACAO_ADD
    , VISITAS_VALIDACAO_REMOVE
    , VISITAS_WPP_ADD
    , VISITAS_WPP_REMOVE
    , VISITAS_JUSTIFIED
    , VISITAS_ATT_CODPARC
    , VISITAS_WHATSAPP_LIST
    , VISITAS_SRCH_CODPARC,
    VISITAS_ATUALIZAR_CAMPOS,
} from '../actions/ActionsTypes';


import { VisitaEntity } from '../entities/VisitaEntity'



const INITIAL_STATE = {
    visitas: []
    , isSaving: false
    , isJustificativa: false
    , logScreen: []
    , historico: []
    , isLoadingSrch: false
    , ramoatividade: []
    , ramoatividadeSelecionado: []

    , produtostrabalha: []
    , produtostrabalhaSelecionado: []

    , produtospretende: []
    , produtospretendeSelecionado: []

    , vendedores: []

    , VisitaEntity

    , validacao: []

    , whatsapp: []
    , codigparc: 0
}


const validacaoExists = (source, item) => {
    return source.validacao.some((e) => e.id === item.id);
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //desativado
        case VISITAS_LIST:
            return { ...state, visitas: action.payload }

        case VISITAS_SAVING:
            return { ...state, isSaving: action.payload }

        case VISITAS_JUSTIFIED:
            return { ...state, isJustificativa: action.payload }


        case VISITAS_LOGSCREEN:
            return { ...state, logScreen: action.payload }

        case VISITAS_HISTORICO_LIST:
            return { ...state, historico: action.payload }

        case VISITAS_RAMOATIVIDADE_LIST:
            return { ...state, ramoatividade: action.payload }

        case VISITAS_RAMOATIVIDADE_SELECIONADO_LIST:
            return { ...state, ramoatividadeSelecionado: action.payload }

        case VISITAS_PRODUTOSTRABALHA_LIST:
            return { ...state, produtostrabalha: action.payload }

        case VISITAS_PRODUTOSTRABALHA_SELECIONADO_LIST:
            return { ...state, produtostrabalhaSelecionado: action.payload }

        case VISITAS_PRODUTOSPRETENDE_LIST:
            return { ...state, produtospretende: action.payload }

        case VISITAS_PRODUTOSPRETENDE_SELECIONADO_LIST:
            return { ...state, produtospretendeSelecionado: action.payload }

        case VISITAS_CHANGE:
            return { ...state, VisitaEntity: { ...state.VisitaEntity, [action.field]: action.payload } }

        case VISITAS_CHANGE_WPP:
            return {
                ...state,
                whatsapp: state.whatsapp.map(
                    (whatsapp, i) => whatsapp.codcontato === action.codcontato ?
                        { ...whatsapp, [action.field]: action.payload }
                        : whatsapp
                )
            }
        case VISITAS_WPP_ADD:
            return { ...state, whatsapp: [...state.whatsapp, action.payload] }

        case VISITAS_WPP_REMOVE:
            return {
                ...state,
                whatsapp: state.whatsapp.filter(whatsapp => whatsapp.codcontato !== action.payload)
            }


        case VISITAS_ENTITY_LOAD:
            return { ...state, VisitaEntity: { ...state.VisitaEntity, [action.field]: action.payload } }

        case VISITAS_VENDEDORES_LIST:
            return { ...state, vendedores: action.payload }

        case VISITAS_VALIDACAO_ADD:
            /*if (validacaoExists(state, action.payload)) {
                return { ...state, validacao: state.validacao.map(c => (c.id === action.payload.id ? action.payload : c)) }
            } else {
                return { ...state, validacao: [...state.validacao, action.payload] }
            }*/

            return { ...state, validacao: [...state.validacao, action.payload] }


        //return { ...state, validacao: [state.validacao.map(c => (c.id === action.payload.id ? action.payload : c))] }
        /*if (validacaoExists(state, [action.payload])) {
            //return { ...state, validacao: [...state.validacao] };
            return { ...state, validacao: [state.validacao] };
        } else {
            return { ...state, validacao: [...state.validacao, action.payload] };    
        }*/
        /*const updatedItems = state.validacao.map(item => {
            if(item.id === action.id){
              return { ...item, ...action.payload }
            }
            return { ...state, validacao:[...state.validacao,action.payload] }
          })*/


        //return { ...state, validacao:[...state.validacao,action.payload] }

        case VISITAS_VALIDACAO_REMOVE:
            return { ...state, validacao: [] }

        case VISITAS_WHATSAPP_LIST:
            return { ...state, whatsapp: action.payload }

        case VISITAS_ATT_CODPARC:
            return {
                ...state,
                codigparc: action.payload
            };
        case VISITAS_SRCH_CODPARC:
            return {
                ...state,
                isLoadingSrch: action.payload
            };

        case VISITAS_ATUALIZAR_CAMPOS:
            return { ...state, VisitaEntity: { ...state.VisitaEntity, [action.field]: action.payload } }



        default:
            return state;
    }
}

