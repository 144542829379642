import {
    PARCEIROS_LIST_SEARCH
    , BAIRROS_LIST_SEARCH
    , PARCEIROS_VALIDACAO_ADD
    , PARCEIROS_VALIDACAO_REMOVE
    , PARCEIROS_UPDATE_BUSCA
    , PARCEIROS_ISSEARCHING
    , PARCEIROS_ATUALPAG
    , PARCEIROS_TOTALPAG
    , PARCEIROS_ATUALCID
    , PARCEIROS_BAIRRSELECT

} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const parceirosUpdateBuscaAction = (event) => {
    return {
        type: PARCEIROS_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}



export const parceirosAddValidacaoAction = (id, campo, mensagem) => {
    return {
        type: PARCEIROS_VALIDACAO_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}


export const parceirosRemoveValidacaoAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PARCEIROS_VALIDACAO_REMOVE, payload: [] })
        resolve([])
    }
    );


export const parceirosListSearchAction = (entidade, loading) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(entidade)
        if (loading == false) {

        } else {
            dispatch({ type: PARCEIROS_ISSEARCHING, payload: true })
        }

        Api.postParceirosSearch(entidade).then(dados => {
            // console.log(dados.DataSource2)
            dispatch({ type: PARCEIROS_LIST_SEARCH, payload: dados.DataSource1 })
            dispatch({ type: BAIRROS_LIST_SEARCH, payload: dados.DataSource2 })
            // console.log(dados)
            dispatch({ type: PARCEIROS_ISSEARCHING, payload: false })
            resolve(dados)
        })
    }
    )



export const parceirosSalvaPagination = (entidade, item) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log('OIIIII')

        dispatch({ type: PARCEIROS_ATUALPAG, payload: entidade })
        dispatch({ type: PARCEIROS_TOTALPAG, payload: item })
        resolve()
    }
    )


export const parceirosSalvaAtualCid = (entidade, item) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PARCEIROS_ATUALCID, payload: entidade })
        resolve()
    }
    )

export const parceirosSalvaBairrosSelected = (entidade, item) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PARCEIROS_BAIRRSELECT, payload: entidade })
        resolve()
    }
    )






