import { useState, Fragment, useEffect } from 'react'
import { connect } from 'react-redux';
import moment from 'moment';
import { BrowserView, MobileView } from 'react-device-detect';
import 'moment/locale/pt-br';
import {
    Dropdown, Table, Button, Modal, Form, Message, Header, Icon, HeaderContent, HeaderSubheader, ModalActions, Card, Grid, Pagination
} from 'semantic-ui-react'
import If from '../../utils/If';
import InputMask from 'react-input-mask';
import {
    parceirosUpdateBuscaAction
    , parceirosAddValidacaoAction
    , parceirosRemoveValidacaoAction
    , parceirosListSearchAction
    , parceirosSalvaPagination
    , parceirosSalvaAtualCid
    , parceirosSalvaBairrosSelected
} from '../../actions/ParceirosActions';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




const Parceiros = ({ navigateToVisits, ...props }) => {
    //actions
    const {
        parceirosUpdateBuscaAction,
        parceirosAddValidacaoAction,
        parceirosRemoveValidacaoAction,
        parceirosListSearchAction,
        parceirosSalvaPagination,
        parceirosSalvaAtualCid,
        parceirosSalvaBairrosSelected,
    } = props;

    const { BuscaParceirosEntity, consulta, bairros, validacao, issearching, totalPag, atualPag, cidadeatual, bairrosSelected } = props;

    const [reachedBottom, setReachedBottom] = useState(false); // Variável de estado para controlar se o usuário alcançou o final da página
    const [cidade, setCidade] = useState('');
    const [open, setOpen] = useState(false)
    const [openMobile, setOpenMobile] = useState(false)
    const [detalhesPopup, setDetalhesPopup] = useState([])
    const [listCuston, setListCuston] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [selectedBairros, setSelectedBairros] = useState([]);
    const [dropdownBairros, setDropdownBairros] = useState(false);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [previousCity, setPreviousCity] = useState('');

    const stateOptions = bairros?.map((state) => ({
        key: state.codbairro,
        text: state.bairro,
        value: state.codbairro,
    }));

    const handleDropdownBairrosChange = (e, { value }) => {
        setSelectedBairros(value);
        parceirosSalvaBairrosSelected(value)
    };

    const handleBusca = () => {
        parceirosRemoveValidacaoAction().then(result => {

            if (BuscaParceirosEntity.cidade === "") {
                parceirosAddValidacaoAction(1, "Cidade", "É necessario preencher o nome da cidade")
                return
            }

            BuscaParceirosEntity.page = 1
            if (validacao.length === 0) {
                //realiza a busca

                BuscaParceirosEntity.filtro = selectedFilter
                BuscaParceirosEntity.bairros = selectedBairros; // Adiciona os bairros selecionados

                console.log(BuscaParceirosEntity)
                parceirosListSearchAction(BuscaParceirosEntity).then(d => {
                    setDropdownBairros(true)
                    let total_cidades = d?.DataSource1?.[0]?.QNTDADCID;
                    let page_size = 70;
                    let totalpages = Math.ceil(total_cidades / page_size);
                    parceirosSalvaPagination(atualPag, totalpages)
                    setTotalRegistros(total_cidades)
                    setTotalPage(totalpages)
                    parceirosSalvaAtualCid(BuscaParceirosEntity.cidade)
                })
            }
        })
    }

    useEffect(() => {
        setDropdownBairros(true)
        setSelectedBairros(bairrosSelected)
        if (cidadeatual !== BuscaParceirosEntity.cidade) {
            setSelectedBairros([]);
            setDropdownBairros(false)

        }
    }, [BuscaParceirosEntity.cidade]);
    useEffect(() => {
        if (BuscaParceirosEntity.cidade === "") {
            return
        } else {
            parceirosListSearchAction(BuscaParceirosEntity, false).then(d => {
                let total_cidades = d?.DataSource1?.[0]?.QNTDADCID;
                let page_size = 70;
                let totalpages = Math.ceil(total_cidades / page_size);
                parceirosSalvaPagination(atualPag, totalpages)
                setTotalRegistros(total_cidades)
                setTotalPage(totalpages)
            })
        }
    }, [atualPag]);
    const onOpenPopup = (usuario) => {
        setOpen(true);
        let user = []
        user.push(usuario)
        setDetalhesPopup(user);
    }

    const onOpenPopupMobile = (usuario) => {
        setOpenMobile(true);
        let user = []
        user.push(usuario)
        setDetalhesPopup(user);
    }

    const handlePageChange = (event, { activePage }) => {
        console.log(activePage);
        parceirosSalvaPagination(activePage, totalPag)
        BuscaParceirosEntity.page = activePage
        parceirosListSearchAction(BuscaParceirosEntity).then(d => {
            let total_cidades = d?.DataSource1?.[0]?.QNTDADCID;
            let page_size = 70;
            let totalpages = Math.ceil(total_cidades / page_size);
            setTotalPage(totalpages)
        })
        window.scrollTo(0, 0);
    };

    const handleDropdownChange = (e, { value }) => {
        setSelectedFilter(value);
    };

    const filtros = [
        { text: 'Todos', value: 0 },
        { text: 'Reativação + 60 dias', value: 2 },
        { text: 'Comprando', value: 3 },
        { text: 'Inativos', value: 4 },
        { text: 'Nunca comprou', value: 1 }
    ];

    // console.log(atualPag)
    // console.log(bairros.length)
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                {detalhesPopup?.map(item => (
                    <Fragment key={item.CODPARC}>
                        <Modal.Header >{item.CODPARC} - {item.razaosocial}</Modal.Header>
                        <Modal.Content >
                            <Modal.Description>
                                <Form>
                                    <Form.Group widths='equal' color='gray'>
                                        <div className="field"><label>{(item.tippessoa == "J" ? "CNPJ" : "CPF")}</label>
                                            <div className="ui fluid input">{item.cgccpf}</div>
                                        </div>
                                        <div className="field"><label>Classificação</label>
                                            <div className="ui fluid input">{item.classificacao}</div>
                                        </div>
                                        <div className="field">
                                            <label>Dias sem comprar:</label>
                                            <div>
                                                {item.DIAS_DE_CADASTRO <= 90 && item.qtdedias == null ? (
                                                    <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                )
                                                    : item.DIAS_DE_CADASTRO <= 90 && item.qtdedias < 90 ? (
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                    ) : item.DIAS_DE_CADASTRO > 90 && item.qtdedias == null ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                    ) : ''}
                                                {item.qtdedias < 60 && item.qtdedias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{item.qtdedias}</div>
                                                ) : item.qtdedias > 60 && item.qtdedias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{item.qtdedias}</div>
                                                ) : null}
                                            </div>

                                        </div>
                                        <div className="field"><label>Vendedor</label>
                                            <div>
                                                {(item.apelido == '<Sem Vendedor>' ? "---" : item.apelido)}
                                            </div>
                                        </div>
                                        <div className="field"><label>Supervisor</label>
                                            <div>
                                                {(item.supervisor == '<Sem Vendedor>' ? "---" : item.supervisor)}
                                            </div>
                                        </div>
                                        <div className="field"><label>Ativo</label>
                                            <div>
                                                {item.ativo}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group widths='equal' color='gray'>
                                        <div className="field"><label>Endereço</label>
                                            <div>
                                                {item.endereco},{item.numeroendereco} {item.complemento}
                                            </div>
                                        </div>
                                        <div className="field"><label>Bairro</label>
                                            <div>
                                                {item.bairro}
                                            </div>
                                        </div>
                                        <div className="field"><label>Cidade</label>
                                            <div>
                                                {item.cidade}
                                            </div>
                                        </div>
                                        <div className="field"><label>Estado</label>
                                            <div>
                                                {item.estado}
                                            </div>
                                        </div>
                                        <div className="field"><label>CEP</label>
                                            <div>
                                                {item.cep}
                                            </div>
                                        </div>
                                        <div className="field"><label>Rota</label>
                                            <div>
                                                {item.rota}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group widths='equal' color='gray'>
                                        <div className="field"><label>PagtoWeb</label>
                                            <div>
                                                {(item.pagtoweb == "S" ? "Sim" : "Não")}
                                            </div>
                                        </div>
                                        <div className="field"><label>Máquinas ADSite</label>
                                            <div>
                                                {item.adsiteqtdemaquinas}
                                            </div>
                                        </div>
                                        <div className="field"><label>Inadimplente</label>
                                            <div>
                                                {(item.inadimplente > 0 ?
                                                    <span style={{ color: 'red' }}>R$ {item.inadimplente}</span>
                                                    : "Não")}
                                            </div>
                                        </div>
                                        <div className="field"><label>Comprou EspaçoFloor</label>
                                            <div>
                                                {(item.comprouespacofl > 0 ? "Sim" : "Não")}
                                            </div>
                                        </div>
                                        <div className="field"><label>Telefone</label>
                                            <div>
                                                {(item.telefone == null ? "---" : item.telefone)}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <div className="field">
                                            <If test={item.dtultcontato != null}>
                                                <Message >
                                                    <Message.Header>Último Contato: {moment(item.dtultcontato).format("DD/MM/YYYY")}</Message.Header>
                                                    <p>{item.comentarios}</p>
                                                </Message>
                                            </If>
                                        </div>
                                        <div className="field">
                                            <If test={item.ultpedido != null}>
                                                <Message>
                                                    <Message.Header>Último Pedido: </Message.Header>
                                                    <p>{item.ultpedido}</p>
                                                </Message>

                                            </If>
                                        </div>
                                    </Form.Group>
                                    <If test={item.msgpesquisamercado != null}>
                                        <Message color='yellow'>
                                            <Message.Header>Comercial/Pesquisa de Mercado</Message.Header>
                                            <p>{item.msgpesquisamercado}</p>
                                        </Message>

                                    </If>
                                    <If test={item.msgvendedor != null}>
                                        <Message positive>
                                            <Message.Header>Mensagem para o Vendedor</Message.Header>
                                            <p>{item.msgvendedor}</p>
                                        </Message>

                                    </If>
                                    <If test={item.conversa != null}>
                                        <Message info>
                                            <Message.Header>Relato/Conversa</Message.Header>
                                            <p>{item.conversa}</p>
                                        </Message>

                                    </If>
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <ModalActions>
                            <Button positive onClick={() => navigateToVisits(item.CODPARC)}>
                                Visitar
                            </Button>
                            <Button negative onClick={() => setOpen(false)}>
                                Fechar
                            </Button>
                        </ModalActions>
                    </Fragment>
                ))}
            </Modal>

            <Modal
                open={openMobile}
                onClose={() => setOpenMobile(false)}
                onOpen={() => setOpenMobile(true)}
            >
                {detalhesPopup?.map(item => (
                    <Fragment key={item.CODPARC}>
                        <Modal.Header >{item.CODPARC} - {item.razaosocial}</Modal.Header>
                        <Modal.Content >
                            <Modal.Description>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>{(item.tippessoa === "J" ? "CNPJ" : "CPF")}</b></label>
                                            <div className="ui fluid input">{item.cgccpf}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Classificação</b></label>
                                            <div className="ui fluid input">{item.classificacao}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>Vendedor</b></label>
                                            <div className="ui fluid input">{(item.apelido == '<Sem Vendedor>' ? "---" : item.apelido)}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Supervisor</b></label>
                                            <div className="ui fluid input">{(item.apelido == '<Sem Vendedor>' ? "---" : item.apelido)}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>Dias sem comprar:</b></label>
                                            <div className="ui fluid input">
                                                {item.DIAS_DE_CADASTRO <= 90 && item.qtdedias == null ? (
                                                    <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                                )
                                                    : item.DIAS_DE_CADASTRO <= 90 && item.qtdedias < 90 ? (
                                                        <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                                    ) : item.DIAS_DE_CADASTRO > 90 && item.qtdedias == null ? (
                                                        <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                                    ) : ''}
                                                {item.qtdedias < 60 && item.qtdedias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{item.qtdedias}</div>
                                                ) : item.qtdedias > 60 && item.qtdedias != null ? (
                                                    <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{item.qtdedias}</div>
                                                ) : null}
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Ativo</b></label>
                                            <div className="ui fluid input"> {item.ativo}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>Cidade</b></label>
                                            <div className="ui fluid input">{item.cidade}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Estado</b></label>
                                            <div className="ui fluid input">{item.estado}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={20}>
                                            <label><b>Bairro</b></label>
                                            <div className="ui fluid input">{item.bairro}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={20}>
                                            <label><b>Endereço</b></label>
                                            <div className="ui fluid input"> {item.endereco},{item.numeroendereco} {item.complemento}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>CEP</b></label>
                                            <div className="ui fluid input">{item.cep}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Rota</b></label>
                                            <div className="ui fluid input">{item.rota}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>PagtoWeb</b></label>
                                            <div className="ui fluid input"> {(item.pagtoweb == "S" ? "Sim" : "Não")}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Máquinas ADSite</b></label>
                                            <div className="ui fluid input">{item.adsiteqtdemaquinas}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <label><b>Inadimplente</b></label>
                                            <div className="ui fluid input"> {(item.inadimplente > 0 ?
                                                <span style={{ color: 'red' }}>R$ {item.inadimplente}</span>
                                                : "Não")}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <label><b>Comprou EspaçoFloor</b></label>
                                            <div className="ui fluid input">{(item.comprouespacofl > 0 ? "Sim" : "Não")}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={20}>
                                            <label><b>Telefone</b></label>
                                            <div className="ui fluid input">{(item.telefone == null ? "---" : item.telefone)}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Form>
                                    <Form.Group widths='equal' >
                                        <div className="field" >
                                            <If test={item.dtultcontato != null}>
                                                <Message style={{ marginTop: '15px' }}>
                                                    <Message.Header>Último Contato: {moment(item.dtultcontato).format("DD/MM/YYYY")}</Message.Header>
                                                    <p>{item.comentarios}</p>
                                                </Message>
                                            </If>
                                        </div>
                                        <div className="field">
                                            <If test={item.ultpedido != null}>
                                                <Message>
                                                    <Message.Header>Último Pedido: </Message.Header>
                                                    <p>{item.ultpedido}</p>
                                                </Message>

                                            </If>
                                        </div>
                                    </Form.Group>
                                    <If test={item.msgpesquisamercado != null}>
                                        <Message color='yellow'>
                                            <Message.Header>Comercial/Pesquisa de Mercado</Message.Header>
                                            <p>{item.msgpesquisamercado}</p>
                                        </Message>

                                    </If>
                                    <If test={item.msgvendedor != null}>
                                        <Message positive>
                                            <Message.Header>Mensagem para o Vendedor</Message.Header>
                                            <p>{item.msgvendedor}</p>
                                        </Message>

                                    </If>
                                    <If test={item.conversa != null}>
                                        <Message info>
                                            <Message.Header>Relato/Conversa</Message.Header>
                                            <p>{item.conversa}</p>
                                        </Message>

                                    </If>
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <ModalActions>
                            <Button positive onClick={() => navigateToVisits(item.CODPARC)}>
                                Visitar
                            </Button>
                            <Button negative onClick={() => setOpenMobile(false)}>
                                Fechar
                            </Button>
                        </ModalActions>
                    </Fragment>
                ))}
            </Modal>

            {/* responsividade para brownser */}
            <BrowserView>
                <Header as='h3' dividing>
                    <Icon name='search' />
                    <HeaderContent>
                        Parceiro
                        <HeaderSubheader>Busca por Cidade</HeaderSubheader>
                    </HeaderContent>
                </Header>

                <Form >
                    <Form.Group widths='equal'>
                        <div className="ui input">
                            <Form.Input name="cidade" placeholder='Nome da Cidade'
                                onChange={parceirosUpdateBuscaAction}
                                value={BuscaParceirosEntity.cidade}
                            />
                        </div>
                    </Form.Group>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                            <Form.Button
                                primary
                                onClick={handleBusca}
                                loading={issearching}
                            >
                                Buscar
                            </Form.Button>
                        </div>

                        <div className="ui input" style={{ width: '190px', marginRight: '15px' }}>
                            <Dropdown
                                options={filtros}
                                selection
                                placeholder='Filtros'
                                name='filtro'
                                fluid
                                onChange={handleDropdownChange}
                                defaultValue={0}
                                value={selectedFilter}
                            />
                        </div>
                        <div style={{ minWidth: '250px' }}>
                            <If test={bairros?.length > 0 && dropdownBairros == true}>
                                <Dropdown
                                    selection
                                    name='Bairros'
                                    options={stateOptions}
                                    fluid
                                    placeholder='Bairros'
                                    search
                                    multiple
                                    onChange={handleDropdownBairrosChange}
                                    value={selectedBairros}
                                />
                            </If>
                        </div>

                    </div>


                    {
                        (validacao.length > 0 ? (
                            <Message negative>
                                {validacao.map(valida => (
                                    <p key={valida.id}>{valida.mensagem}</p>
                                ))}

                            </Message>
                        ) : (
                            <div></div>
                        ))
                    }


                </Form>

                <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <If test={consulta?.length > 0}>
                        <span><b>Encontramos {totalRegistros} registro(s)</b></span>
                    </If>
                </div>




                <If test={consulta?.length > 0}>

                    <StyledTable id="listFechamento" compact celled >
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell>Codparc</Table.HeaderCell>
                                <Table.HeaderCell>Parceiro</Table.HeaderCell>
                                <Table.HeaderCell>Dias S/ Comprar</Table.HeaderCell>
                                <Table.HeaderCell>Cidade/Estado</Table.HeaderCell>
                                <Table.HeaderCell>Bairro</Table.HeaderCell>
                                <Table.HeaderCell>Telefone</Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                </Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>

                            {consulta?.map(item => (
                                <Table.Row key={"parc_" + item.CODPARC} warning={(item.inadimplente > 0 ? true : false)}>
                                    <Table.Cell>{item.CODPARC}</Table.Cell>
                                    <Table.Cell>{item.razaosocial}</Table.Cell>
                                    <Table.Cell>
                                        {item.DIAS_DE_CADASTRO <= 90 && item.qtdedias == null ? (
                                            <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</div>
                                        )
                                            : item.DIAS_DE_CADASTRO <= 90 && item.qtdedias < 90 ? (
                                                <div className="ui fluid input" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</div>
                                            ) : item.DIAS_DE_CADASTRO > 90 && item.qtdedias == null ? (
                                                <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</div>
                                            ) : ''}
                                        {item.qtdedias < 60 && item.qtdedias != null ? (
                                            <div className="ui fluid input" style={{ color: 'black', marginBottom: '5px' }}>{item.qtdedias}</div>
                                        ) : item.qtdedias > 60 && item.qtdedias != null ? (
                                            <div className="ui fluid input" style={{ color: 'red', marginBottom: '5px' }}>{item.qtdedias}</div>
                                        ) : null}



                                    </Table.Cell>
                                    <Table.Cell>{item.cidade}/ {item.estado}</Table.Cell>
                                    <Table.Cell>{item.bairro}</Table.Cell>
                                    <Table.Cell> {(item.telefone == null ? "---" : item.telefone)}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <Button
                                            onClick={() => onOpenPopup(item)}
                                            basic
                                            icon={'search'}
                                            color={'blue'}
                                            size="mini"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}

                        </StyledTableBody>
                    </StyledTable>
                    <div style={{ textAlign: 'right' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            lastItem={totalPag}
                            siblingRange={1}
                            totalPages={totalPag}
                            activePage={atualPag}
                            onPageChange={handlePageChange}
                        />
                    </div>

                </If>

            </BrowserView>

            {/* responsividade para mobiles */}
            <MobileView>
                <Header as='h3' dividing>
                    <Icon name='search' />
                    <HeaderContent>
                        Parceiro
                        <HeaderSubheader>Busca por Cidade</HeaderSubheader>
                    </HeaderContent>
                </Header>

                <Form >
                    <Form.Group widths='equal'>

                        <Form.Input name="cidade" fluid placeholder='Nome da Cidade'
                            onChange={parceirosUpdateBuscaAction}
                            value={BuscaParceirosEntity.cidade}
                        />
                    </Form.Group>
                    <Form.Group widths='eleven'><label>&nbsp;&nbsp;&nbsp;</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Button primary
                                onClick={handleBusca}
                                loading={issearching}
                            >Buscar</Form.Button>

                            <div className="ui input" style={{ width: '190px' }}>
                                <Dropdown
                                    options={filtros}
                                    selection
                                    placeholder='Filtros'
                                    name='filtro'
                                    fluid
                                    onChange={handleDropdownChange}
                                    defaultValue={0}
                                    value={selectedFilter}
                                />
                            </div>

                        </div>
                        <div style={{ width: '100%', marginTop: '15px', marginLeft: '10px', marginRight: '10px' }}>
                            <If test={bairros.length > 0 && dropdownBairros == true}>
                                <Dropdown
                                    selection
                                    name='Bairros'
                                    options={stateOptions}
                                    fluid
                                    placeholder='Bairros'
                                    search
                                    multiple
                                    onChange={handleDropdownBairrosChange}
                                    value={selectedBairros}
                                />
                            </If>
                        </div>


                    </Form.Group>
                    {
                        (validacao.length > 0 ? (
                            <Message negative>
                                {validacao.map(valida => (
                                    <p key={valida.id}>{valida.mensagem}</p>
                                ))}

                            </Message>
                        ) : (
                            <div></div>
                        ))
                    }


                </Form>
                <If test={consulta?.length > 0} >
                    <div style={{ paddingBottom: '10px', paddingLeft: '15px', paddingTop: '15px' }}>
                        <b>Encontramos {totalRegistros} registro(s)</b>
                    </div>

                </If>



                <If test={consulta?.length > 0}>
                    <div style={{ textAlign: 'center', paddingBottom: '15px' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            lastItem={totalPag}
                            siblingRange={0}
                            totalPages={totalPag}
                            activePage={atualPag}
                            onPageChange={handlePageChange}
                        />
                    </div>
                    {consulta?.map(item => (
                        <Card.Group key={item.CODPARC} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card >
                                <Card.Content>
                                    <Card.Header>{item.razaosocial} ({item.CODPARC})</Card.Header>
                                    <Card.Description>

                                        {item.DIAS_DE_CADASTRO <= 90 && item.qtdedias == null ? (

                                            <span><b>Dias S/ Comprar: </b><span className="ui fluid" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo, nunca comprou.</span><br></br></span>
                                        )
                                            : item.DIAS_DE_CADASTRO <= 90 && item.qtdedias < 90 ? (
                                                <span><b>Dias S/ Comprar: </b><span className="ui fluid" style={{ color: 'blue', marginBottom: '5px' }}>Cliente novo.</span><br></br></span>
                                            ) : item.DIAS_DE_CADASTRO > 90 && item.qtdedias == null ? (
                                                <span><b>Dias S/ Comprar: </b><span className="ui fluid" style={{ color: 'red', marginBottom: '5px' }}>Cliente antigo, nunca comprou!</span><br></br></span>
                                            ) : ''}
                                        {item.qtdedias < 60 && item.qtdedias != null ? (
                                            <span><b>Dias S/ Comprar: </b><span className="ui fluid" style={{ color: 'black', marginBottom: '5px' }}>{item.qtdedias} dias</span><br></br></span>
                                        ) : item.qtdedias > 60 && item.qtdedias != null ? (
                                            <>
                                                <span><b>Dias S/ Comprar: </b><span className="ui fluid" style={{ color: 'red' }}> {item.qtdedias} dias</span><br></br></span>
                                            </>) : null}

                                        <span><b>Cidade/ Estado:</b></span><br></br>
                                        <span>{item.cidade}/ {item.estado}</span><br></br>
                                        <span><b>Bairro:</b></span><br></br>
                                        <span>{item.bairro}</span><br></br>
                                        <span><b>Telefone: </b><span >{(item.telefone == null ? "---" : item.telefone)}</span><br></br></span><br></br>
                                        <Button basic fluid color='green' style={{ marginTop: '5px' }} onClick={() => onOpenPopupMobile(item)}>
                                            Informações
                                        </Button>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    ))}

                    <div style={{ textAlign: 'center', paddingTop: '15px', marginLeft: '10px' }}>
                        <Pagination
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={1}
                            lastItem={totalPag}
                            siblingRange={0}
                            totalPages={totalPag}
                            activePage={atualPag}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </If>
            </MobileView>
        </>
    );
}

const mapStateToProps = (state) => ({
    BuscaParceirosEntity: state.ParceirosReducer.BuscaParceirosEntity
    , consulta: state.ParceirosReducer.consulta
    , bairros: state.ParceirosReducer.bairros
    , validacao: state.ParceirosReducer.validacao
    , issearching: state.ParceirosReducer.issearching
    , totalPag: state.ParceirosReducer.totalPag
    , atualPag: state.ParceirosReducer.atualPag
    , cidadeatual: state.ParceirosReducer.atualCid
    , bairrosSelected: state.ParceirosReducer.bairrosSelected

})

export default connect(mapStateToProps, {
    parceirosUpdateBuscaAction
    , parceirosAddValidacaoAction
    , parceirosRemoveValidacaoAction
    , parceirosListSearchAction
    , parceirosSalvaPagination
    , parceirosSalvaAtualCid
    , parceirosSalvaBairrosSelected
}
)(Parceiros);