
import {
    PARCEIROS_LIST_SEARCH
    , BAIRROS_LIST_SEARCH
    , PARCEIROS_VALIDACAO_ADD
    , PARCEIROS_VALIDACAO_REMOVE
    , PARCEIROS_UPDATE_BUSCA
    , PARCEIROS_ISSEARCHING
    , PARCEIROS_ATUALPAG
    , PARCEIROS_TOTALPAG
    , PARCEIROS_ATUALCID
    , PARCEIROS_BAIRRSELECT
} from '../actions/ActionsTypes'


import { BuscaParceirosEntity } from '../entities/BuscaParceirosEntity'

const INITIAL_STATE = {
    consulta: []
    , bairros: []
    , bairrosSelected: []
    , issearching: false
    , validacao: []
    , BuscaParceirosEntity
    , atualPag: 1
    , totalPag: 1
    , atualCid: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PARCEIROS_LIST_SEARCH:
            return { ...state, consulta: action.payload }

        case BAIRROS_LIST_SEARCH:
            return { ...state, bairros: action.payload }

        case PARCEIROS_UPDATE_BUSCA:
            return { ...state, BuscaParceirosEntity: { ...state.BuscaParceirosEntity, [action.field]: action.payload } }

        case PARCEIROS_VALIDACAO_ADD:
            return { ...state, validacao: [...state.validacao, action.payload] }

        case PARCEIROS_VALIDACAO_REMOVE:
            return { ...state, validacao: [] }

        case PARCEIROS_ISSEARCHING:
            return { ...state, issearching: action.payload }

        case PARCEIROS_ATUALPAG:
            return { ...state, atualPag: action.payload }

        case PARCEIROS_TOTALPAG:
            return { ...state, totalPag: action.payload }

        case PARCEIROS_ATUALCID:
            return { ...state, atualCid: action.payload }

        case PARCEIROS_BAIRRSELECT:
            return { ...state, bairrosSelected: action.payload }

        default:
            return state;
    }
}


