export const VisitaEntity = {
    codparc: 0,
    email: '',
    telefoneprincipal: '',
    telefonesecundario: '',
    codvend: '',
    ramoatividade: '',
    produtosquetrabalha: '',
    produtosquepretende: '',

    interessedisplay: '',
    interesseadsite: '',
    interessetreinamentos: '',

    msgvendedor: '',//temp
    finreavaliacaodecredito: '',//temp
    finpagardividas: '',//temp
    comfaltacontato: '',//temp
    msgpesquisamercado: '',//temp
    logmaterialdanificado: '',//temp
    logentregaobrasobeescada: ''//temp
    , km: 0
    , conversa: ''
    , sigiloso: ''
    , naoalteraconsultor: ''
    , justificativa: ''
    , kminicial: 0
    , kmfinal: 0
    , dias_de_cadastro: ''
    , dias_sem_comprar: ''
    , dias_sem_cttprog: ''
    , inadimplente: ''

    , mostrouamost: ''
    , mostroub2b: ''
    , classificacaofin: ''

    , dtclassificacaofin: ''
    , valormina: ''
    , valorminb: ''
    , valormeses: ''
    , mesestotal: ''
}   