import React, { Component } from 'react'
import PropType from 'prop-types'
import moment from 'moment';
import 'moment/locale/pt-br'

import { connect } from 'react-redux';

import { Throttle } from 'react-throttle';
import { DebounceInput } from 'react-debounce-input';

import InputMask from 'react-input-mask';



import If from '../../utils/If';

import { loginValidAction } from '../../actions/LoginActions';

import {
    novoClienteHandleChangeAction
    , novoClienteList
    , novoClienteRemoveValidacao
    , novoClienteAddValidacao
    , novoClienteSaveAction
    , novoClienteClearAction
    , novoClienteCEPAction
    , novoClienteDocumentoAction
    , novoClienteChangeTabAction
} from '../../actions/NovoClienteActions';

import LogScreen from '../logscreen/LogScreen'

import {
    Label, Segment,
    Message, Checkbox, Form
    , TextArea, Table, Divider, Dropdown, Radio, Header, Icon, HeaderContent, HeaderSubheader
} from 'semantic-ui-react'



import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 5px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }`

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}`

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}`


class NovoCliente extends Component {

    state = {
        dropVend: '',
        dropRamo: [],
        dropTrabalha: [],
        dropPretende: [],
        radio: '1'
    };




    constructor(props) {
        super(props)

    }


    searchCep = (query) => {
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'cep', 'value': query } })

        if (query == '' || query == '     -   ') {

        } else {
            //if(query.trimRight().length>8){
            if (query.toString().replace(/ /g, '').trim().length > 8) {

                this.props.novoClienteCEPAction(query.replace('-', '')).then(d => { })

            }

        }

    }

    handleUfs = (e, { value }) => {
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'estado', 'value': value.toString() } })
    }

    handleChangeRadio = (e, { value }) => {
        this.setState({ radio: value })
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'cnpj', 'value': '' } })
    }

    //DropDowns
    handleVendedores = (e, { value }) => {
        this.setState({ dropVend: value.toString() })
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'codvend', 'value': value.toString() } })
    }

    handleRamoAtividade = (e, { value }) => {
        this.setState({ dropRamo: value.toString().split(',') })
        //  console.log('state:'+this.state.dropRamo)
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'ramoatividade', 'value': value.toString() } })
    }

    handleProdutosTrabalha = (e, { value }) => {
        this.setState({ dropTrabalha: value.toString().split(',') })
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'produtosquetrabalha', 'value': value.toString() } })
    }

    handleProdutosPretende = (e, { value }) => {
        this.setState({ dropPretende: value.toString().split(',') })
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'produtosquepretende', 'value': value.toString() } })
    }


    componentDidMount() {
        //      this.props.loginValidAction(this.props.isAuthenticated, this.props.history)
        this.props.novoClienteList()
        this.setState({ dropVend: '', dropRamo: [], dropTrabalha: [], dropPretende: [] })
        this.props.novoClienteClearAction();

    }


    isEmail = (email = null) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }




    isCNPJ = (cnpj = null) => {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return false;

        if (cnpj.length != 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        let i = 0;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;

    }


    isCPF = (cpf = null) => {
        // Remove os pontos/traço da expressão regular, caso exista
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf == '') return false;

        // Elimina CPFs invalidos conhecidos    
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            return false;


        // Valida 1o digito 
        let add = 0;
        let i;

        for (i = 0; i < 9; i++) {
            add += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) {
            rev = 0;
        }

        if (rev != parseInt(cpf.charAt(9))) {
            return false;
        }

        // Valida 2o digito 
        add = 0;
        for (i = 0; i < 10; i++) {
            add += parseInt(cpf.charAt(i)) * (11 - i);
        }
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) {
            rev = 0;
        }
        if (rev != parseInt(cpf.charAt(10))) {
            return false;
        }
        return true;
    }

    Resgata = (documento, tipo) => {
        //console.log(documento+'|'+tipo)
        this.props.novoClienteDocumentoAction(documento, tipo);

    }

    CarregaVisita = () => {
        this.props.novoClienteChangeTabAction(0);
    }


    salvarVisita = (formData) => {

        let error = 0;

        //resgatando código do consultor
        this.props.novoClienteHandleChangeAction({ target: { 'name': 'codconsultor', 'value': this.props.codconsultor } })

        this.props.novoClienteRemoveValidacao().then(result => {
            if (this.props.ClienteEntity.nome === '') {
                this.props.novoClienteAddValidacao(1, "Nome", "É necessário preencher")
            }


            if (this.state.radio === '1' && this.state.radio != '3') {
                if (this.isCNPJ(this.props.ClienteEntity.cnpj.toString()) === false || this.props.ClienteEntity.cnpj === '') {
                    this.props.novoClienteAddValidacao(2, "CNPJ", "É necessário preencher corretamente")
                    error = 1
                }

            } else if (this.state.radio === '2' && this.state.radio != '3') {

                if (this.isCPF(this.props.ClienteEntity.cnpj.toString()) === false || this.props.ClienteEntity.cnpj === '') {
                    this.props.novoClienteAddValidacao(2, "CPF", "É necessário preencher corretamente")
                    error = 1
                }
            }



            if (this.isEmail(this.props.ClienteEntity.email) === false) {
                this.props.novoClienteAddValidacao(3, "E-mail", "É necessário preencher")
                error = 1
            }

            if (this.props.ClienteEntity.telefoneprincipal === '' || this.props.ClienteEntity.telefoneprincipal.toString().trim().length < 11) {
                this.props.novoClienteAddValidacao(4, "Telefone Principal", "É necessário preencher corretamente")
                error = 1
            }

            if (this.props.ClienteEntity.telefonesecundario.toString().trim().length > 0 && this.props.ClienteEntity.telefonesecundario.toString().trim().length < 11) {
                this.props.novoClienteAddValidacao(5, "Telefone Secundário", "Está inválido")
                error = 1
            }

            if (this.state.dropVend.toString().trim() === "" || this.state.dropVend.toString().trim() === "0") {

                this.props.novoClienteAddValidacao(6, "Vendedor", "Selecione o vendedor que será responsável")
                error = 1
            }

            if (this.props.ClienteEntity.cep.toString().replace(/ /g, '').trim().length != 9) {
                this.props.novoClienteAddValidacao(7, "CEP", "Cep inválido")
                error = 1
            }

            if (this.props.ClienteEntity.endereco.toString().trim() === '') {
                this.props.novoClienteAddValidacao(8, "Endereço", "É necessário preencher")
            }

            if (this.props.ClienteEntity.numero === '') {
                this.props.novoClienteAddValidacao(9, "Número", "É necessário preencher")
            }

            if (this.props.ClienteEntity.bairro.toString().trim() === '') {
                this.props.novoClienteAddValidacao(10, "Bairro", "É necessário preencher")
            }

            if (this.props.ClienteEntity.cidade.toString().trim() === '') {
                this.props.novoClienteAddValidacao(11, "Cidade", "É necessário preencher")
            }

            if (this.props.ClienteEntity.estado === '') {
                this.props.novoClienteAddValidacao(12, "Estado", "É necessário preencher")
            }

            if (this.state.dropRamo == "") {
                this.props.novoClienteAddValidacao(13, "Ramo", "É necessário preencher o ramo de atividade");
                error = 1;
            }



            if (this.props.validacao.length === 0) {
                // console.log('salvar')
                this.props.novoClienteSaveAction(this.props.ClienteEntity).then(result => {
                    //console.log('salvou')
                    if (result.gravar = 'OK') {
                        this.setState({ dropVend: '', dropRamo: [], dropTrabalha: [], dropPretende: [] })
                        //console.log('OK')
                    }

                })

            }

        })


    }

    render() {

        let { visitas, historico, isSaving, logScreen, history, existecodparc } = this.props

        const { value,
            dropVend, dropRamo, dropTrabalha, dropPretende
        } = this.state



        let { ramoatividade
            , produtostrabalha
            , produtospretende
            , ClienteEntity, vendedores

        } = this.props


        let ufs = [
            { key: "AC", text: "AC", value: "AC" }
            , { key: "AL", text: "AL", value: "AL" }
            , { key: "AM", text: "AM", value: "AM" }
            , { key: "AP", text: "AP", value: "AP" }
            , { key: "BA", text: "BA", value: "BA" }
            , { key: "CE", text: "CE", value: "CE" }
            , { key: "DF", text: "DF", value: "DF" }
            , { key: "ES", text: "ES", value: "ES" }
            , { key: "GO", text: "GO", value: "GO" }
            , { key: "MA", text: "MA", value: "MA" }
            , { key: "MG", text: "MG", value: "MG" }
            , { key: "MS", text: "MS", value: "MS" }
            , { key: "MT", text: "MT", value: "MT" }
            , { key: "PA", text: "PA", value: "PA" }
            , { key: "PB", text: "PB", value: "PB" }
            , { key: "PE", text: "PE", value: "PE" }
            , { key: "PI", text: "PI", value: "PI" }
            , { key: "PR", text: "PR", value: "PR" }
            , { key: "RJ", text: "RJ", value: "RJ" }
            , { key: "RN", text: "RN", value: "RN" }
            , { key: "RO", text: "RO", value: "RO" }
            , { key: "RR", text: "RR", value: "RR" }
            , { key: "RS", text: "RS", value: "RS" }
            , { key: "SC", text: "SC", value: "SC" }
            , { key: "SE", text: "SE", value: "SE" }
            , { key: "SP", text: "SP", value: "SP" }
            , { key: "TO", text: "TO", value: "TO" }
        ]




        return (
            <>

                <Header as='h3' dividing>
                    <Icon name='add user' />
                    <HeaderContent>
                        Novo Cliente
                        <HeaderSubheader>Cadastre um novo cliente</HeaderSubheader>
                    </HeaderContent>
                </Header>

                <Form>
                    <Form.Group widths='equal' color='gray'>


                        <div className="field">
                            <label>Tipo do cliente:</label>
                            <Radio
                                label={`Pessoa Jurídica`}
                                name='radioGroup'
                                value="1"
                                checked={this.state.radio === '1'}
                                onChange={this.handleChangeRadio}
                            />
                            <Radio
                                label={`Pessoa Física`}
                                name='radioGroup'
                                value="2"
                                checked={this.state.radio === '2'}
                                onChange={this.handleChangeRadio}
                            />

                            <If test={this.props.tipousuario == 'R'}>
                                <Radio
                                    label={`Sem DOC`}
                                    name='radioGroup'
                                    value="3"
                                    checked={this.state.radio === '3'}
                                    onChange={this.handleChangeRadio}
                                />

                            </If>

                        </div>

                        {
                            /* <DebounceInput
                                minLength={2}
                                debounceTimeout={1000}
                                onChange={event => this.searchQuery(event.target.value)}
                                maxLength={9}
                                placeholder="Código do Parceiro..."
                                value={this.state.query}
                            />*/
                        }

                        <div className="field">
                            <If test={this.state.radio != '3'}>
                                <label>{(this.state.radio === '1' ? 'CNPJ:' : 'CPF:')}</label>


                                <InputMask mask={(this.state.radio === '1' ? '99.999.999/9999-99' : '999.999.999-99')} maskChar=" " name="cnpj"
                                    placeholder={(this.state.radio === '1' ? 'CNPJ do cliente' : 'CPF do cliente')}
                                    value={ClienteEntity.cnpj}
                                    onChange={this.props.novoClienteHandleChangeAction}

                                    onBlur={event => this.Resgata(event.target.value, this.state.radio)}

                                />



                            </If>
                            <If test={existecodparc != 0}>
                                <a href="#"><div class="ui pointing above prompt label" onClick={event => this.CarregaVisita()}>Já existe o parceiro {existecodparc} cadastrado com o documento informado</div></a>
                            </If>

                        </div>


                        <div className="field">
                            <Form.Input name="nome" fluid label="Nome:" placeholder="Nome"
                                value={ClienteEntity.nome}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={50}

                            />
                            <div className="ui fluid input"></div>
                        </div>

                        <div className="field">
                            <Form.Input name="email" fluid label="E-mail:" placeholder="E-mail"
                                value={ClienteEntity.email}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={50}
                            />
                            <div className="ui fluid input"></div>
                        </div>

                        <div className="field">
                            <label>Telefone Principal:</label>
                            <InputMask mask="99 999999999" maskChar=" " name="telefoneprincipal" placeholder="Telefone Principal"
                                value={ClienteEntity.telefoneprincipal}
                                onChange={this.props.novoClienteHandleChangeAction}
                            />
                        </div>

                        <div className="field">
                            <label>Telefone Secundário:</label>
                            <InputMask id="telefonesecundario" mask="99 999999999" maskChar=" " name="telefonesecundario"
                                value={ClienteEntity.telefonesecundario}
                                onChange={this.props.novoClienteHandleChangeAction}
                            />
                        </div>

                    </Form.Group>

                    <Form.Group widths='equal' color='gray'>
                        <div className="field">
                            <label>CEP:</label>

                            <DebounceInput
                                element={InputMask}
                                mask={'99999-999'}
                                maskChar=" "
                                name="cep"
                                minLength={2}
                                debounceTimeout={4000}

                                onChange={event => this.searchCep(event.target.value)}

                                placeholder="Código do Parceiro..."
                                value={ClienteEntity.cep}


                            />


                        </div>

                        <div className="field">
                            <Form.Input name="endereco" fluid label="Endereço:" placeholder="Endereço"
                                value={ClienteEntity.endereco}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={60}
                            />
                            <div className="ui fluid input"></div>
                        </div>
                        <div className="field">
                            <label>Número:</label>
                            <InputMask mask={'99999'} maskChar=" " name="numero"
                                placeholder={'Número'}
                                value={ClienteEntity.numero}
                                onChange={this.props.novoClienteHandleChangeAction}
                            />
                        </div>
                        <div className="field">
                            <Form.Input name="complemento" fluid label="Complemento:" placeholder="Complemento"
                                value={ClienteEntity.complemento}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={30}
                            />
                            <div className="ui fluid input"></div>
                        </div>


                    </Form.Group>


                    <Form.Group widths='equal' color='gray'>
                        <div className="field">
                            <Form.Input name="bairro" fluid label="Bairro:" placeholder="Bairro"
                                value={ClienteEntity.bairro}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={100}
                            />
                            <div className="ui fluid input"></div>
                        </div>
                        <div className="field">
                            <Form.Input name="cidade" fluid label="Cidade:" placeholder="Cidade"
                                value={ClienteEntity.cidade}
                                onChange={this.props.novoClienteHandleChangeAction}
                                maxLength={100}
                            />
                            <div className="ui fluid input"></div>
                        </div>

                        <div className="field">
                            <label>Estado:</label>
                            <div>


                                <Dropdown
                                    search
                                    options={ufs}
                                    selection
                                    placeholder='Estado'
                                    name='estado'
                                    fluid
                                    //defaultValue={visitas[0].codigovendedor.toString()}
                                    value={ClienteEntity.estado}
                                    onChange={this.handleUfs}
                                />
                            </div>

                        </div>
                    </Form.Group>

                    <Form.Group widths='equal' color='gray'>


                        <div className="field">
                            <label>Vendedor:</label>
                            <div>
                                <Dropdown
                                    options={vendedores}
                                    selection
                                    placeholder='Vendedores'
                                    name='vendedor'
                                    fluid
                                    search
                                    //defaultValue={visitas[0].codigovendedor.toString()}
                                    value={dropVend}
                                    onChange={this.handleVendedores}
                                />
                            </div>

                        </div>

                        <div className="field">
                            <label>Ramo de atividade:</label>
                            <div>
                                <Dropdown
                                    options={ramoatividade}
                                    selection
                                    placeholder='Ramo de atividade'
                                    name='ramoatividade'
                                    multiple
                                    selection
                                    fluid
                                    value={dropRamo}
                                    //defaultValue={ramoatividadeSelecionado}
                                    onChange={this.handleRamoAtividade}

                                />
                            </div>
                        </div>
                        <div className="field">
                            <label>Produtos que trabalha:</label>
                            <div>
                                <Dropdown
                                    options={produtostrabalha}
                                    selection
                                    placeholder='Produtos que trabalha'
                                    name='produtostrabalha'
                                    multiple
                                    selection
                                    fluid
                                    value={dropTrabalha}
                                    //defaultValue={produtostrabalhaSelecionado}
                                    onChange={this.handleProdutosTrabalha}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label>Produtos que pretende trabalhar:</label>
                            <div>
                                <Dropdown
                                    options={produtospretende}
                                    selection
                                    placeholder='Produtos que pretende trabalhar'
                                    name='produtospretende'
                                    multiple
                                    selection
                                    fluid
                                    value={dropPretende}
                                    //defaultValue={produtospretendeSelecionado}
                                    onChange={this.handleProdutosPretende}
                                />
                            </div>
                        </div>

                    </Form.Group>


                    {
                        (this.props.validacao.length > 0 || this.props.logScreen.filter(e => e.status == 'Error').length > 0 ? (
                            <Message negative>
                                {this.props.validacao.map(valida => (
                                    <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                ))}
                                {logScreen.filter(e => e.status == 'Error').map(valida => (
                                    <p key={valida.id}>{valida.item} - {valida.mensagem}</p>
                                ))}
                            </Message>
                        ) : (
                            <div></div>
                        ))
                    }


                    <LogScreen logData={logScreen} />

                    <Form.Button primary
                        loading={isSaving}
                        onClick={this.salvarVisita.bind(this)}
                    >Salvar</Form.Button>

                </Form>




            </>

        )
    }
}


const mapStateToProps = state => (
    {
        ClienteEntity: state.NovoClienteReducer.ClienteEntity
        , ramoatividade: state.NovoClienteReducer.ramoatividade
        , produtostrabalha: state.NovoClienteReducer.produtostrabalha
        , produtospretende: state.NovoClienteReducer.produtospretende
        , vendedores: state.NovoClienteReducer.vendedores
        , validacao: state.NovoClienteReducer.validacao
        , codconsultor: state.LoginReducer.codigoConsultor
        , logScreen: state.NovoClienteReducer.logScreen
        , isSaving: state.NovoClienteReducer.isSaving
        , tipousuario: state.LoginReducer.tipousuario
        , existecodparc: state.NovoClienteReducer.existecodparc
    }
);


export default connect(mapStateToProps, {
    novoClienteHandleChangeAction
    , novoClienteList
    , novoClienteRemoveValidacao
    , novoClienteAddValidacao
    , novoClienteSaveAction
    , novoClienteClearAction
    , novoClienteCEPAction
    , novoClienteDocumentoAction
    , novoClienteChangeTabAction
})(NovoCliente);






