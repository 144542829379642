const api = process.env.REACT_APP_API;

let token = localStorage.token;
if (!token) {
    token = localStorage.token = Math.random().toString(36).substr(-8);
}

/*const headers = {
    'Accept': 'application/json',
    'Authorization': 'Bearer '+sessionStorage.getItem("tokenAPI")
}*/

function headers() {
    let headerapi = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenAPI')
    }
    return headerapi
}

export const postToken = (FormData) =>
    fetch(`${api}/token/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(FormData)
    }).then(res => res.json());



export const postLogin = (FormData) =>
    fetch(`${api}/login/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(FormData)
    }).then(res => res.json());

export const getAllVisitas = (idparceiro) =>
    fetch(`${api}/visitas/${idparceiro}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());
/*
export const getAllVisitas = (idparceiro) =>
    fetch(`${api}/visitas/${idparceiro}`, {
        headers()
}).then(res => res.json());
*/

// ROTA PARA PRODUÇÃO
export const saveVisita = (FormData) =>
    fetch(`${api}/visitas/savecrm/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(FormData)
    }).then(res => res.json());

// ROTA PARA DEBUG E PUBLICAÇÃO EM QA
// export const saveVisita = (FormData) =>
//     fetch(`${api}/visitas/save/`, {
//         method: 'POST',
//         headers: {
//             ...headers(),
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(FormData)
//     }).then(res => res.json());




export const saveCliente = (ClienteEntity) =>
    fetch(`${api}/cliente/save/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(ClienteEntity)
    }).then(res => res.json());



export const getAllVisitasHistorico = (idparceiro) =>
    fetch(`${api}/visitas/historico/${idparceiro}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getAllRamosProdutos = () =>
    fetch(`${api}/ramosprodutos`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const getCEP = (cep) =>
    fetch(`${api}/correios/${cep}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());


export const postParceirosSearch = (entidade) =>
    fetch(`${api}/pesquisa/parceiros/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

// export const postParceirosSearch = (entidade) =>
//     fetch(`${api}/pesquisa/parceiroscrm/`, {
//         method: 'POST',
//         headers: {
//             ...headers(),
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(entidade)
//     }).then(res => res.json());



export const getDocumento = (documento, tipo) =>
    fetch(`${api}/documento/${documento}/${tipo}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());

export const getListaContatos = (entidade) =>
    fetch(`${api}/whatsapp/lista`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postCriaContato = (entidade) =>
    fetch(`${api}/whatsapp/cria/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postContatoAtualiza = (entidade) =>
    fetch(`${api}/whatsapp/atualiza/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postContatoExclui = (entidade) =>
    fetch(`${api}/whatsapp/exclui/`, {
        method: 'POST',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const getKpiConsultor = (entidade) =>
    fetch(`${api}/kpivisitas/lista/${entidade}`, {
        method: 'GET',
        headers: {
            ...headers(),
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());








