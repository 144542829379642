import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Icon } from 'semantic-ui-react';
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
var moment = require('moment');
import styled from 'styled-components';
import { kpivisitasListAction } from '../../actions/KpivisitasActions';
import CardDashboard from './CardDashboard';
import CardGraph from './CardGraph';
import './ToggleButton.css'
import { KpiSkeleton, KpiSkeletonMobile } from './KpiSkeleton';

function KpiConsultor(props) {
    const [selectedAnoMes, setSelectedAnoMes] = useState(null);
    const [exibirTodos, setExibirTodos] = useState(false);

    const { kpivisitasListAction } = props;

    const { kpivisitas, codigoConsultor, isloading } = props;
    const [checked, setChecked] = useState(false); // store value

    const handleChange = (e) => setChecked(e.target.checked)


    useEffect(() => {
        kpivisitasListAction(codigoConsultor);
    }, []);

    const StyledDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width: 100% !important;
    }
    span{
        font-size:10px !important;
        width:80px !important;
    }
}`

    const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

    const Switch = styled.div`
  position: relative;
  width: 50px;
  height: 28px;
  background: #0d2637;
  border-radius: 32px;
  padding: 10px;
  transition: 1s ease-in;

  &:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
    transition: 1s ease-in;
  }
`;

    const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: #00c766;

  &:before {
    transform: translate(20px, -50%);
  }
  }
`;

    const arrayAnos = [...new Set(kpivisitas.map(item => moment(item.dt_ref).format('YYYY/MM')))];
    arrayAnos.sort((a, b) => new Date(b.replace('/', '-')) - new Date(a.replace('/', '-')));

    const filteredData = selectedAnoMes ? kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === selectedAnoMes) : kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === arrayAnos[0]);
    const filteredDataAnt = selectedAnoMes ? kpivisitas.filter(item => moment(item.dt_ref).subtract('months', -1).format('YYYY/MM') === selectedAnoMes) : kpivisitas.filter(item => moment(item.dt_ref).format('YYYY/MM') === arrayAnos[1]);

    const formattedAnoMes = arrayAnos.map((dt_ref) => ({
        key: dt_ref,
        text: dt_ref,
        value: dt_ref,
    }));

    // Ordenando em ordem decrescente
    arrayAnos.sort((a, b) => b.localeCompare(a));

    const mesAtual = arrayAnos[0];
    const possuiMesesAnteriores = arrayAnos.length > 1;

    const handleAnoSelect = (e, { value }) => {
        setSelectedAnoMes(value);
    }

    function iconComponent(dado_atual, chave, index) {

        const valorAnterior = filteredDataAnt[index] ? filteredDataAnt[index][chave] : undefined;

        if (valorAnterior !== undefined && dado_atual !== null) {
            if (dado_atual > valorAnterior) {
                return <Icon name='arrow alternate circle up outline' color='green' />
            } else if (dado_atual < valorAnterior) {
                return <Icon name='arrow alternate circle down outline' color='red' />
            } else if (dado_atual === valorAnterior) {
                return
            }
        } else {
            return null;
        }
    }

    const tratarNumero = (valor) => {
        // Dividir por 1000 e remover as casas decimais
        const valorFormatado = Number(valor / 1000).toFixed(0);

        // Retornar o número formatado com "R$" no início
        return 'R$' + valorFormatado + 'k';
    };

    let ano, mes; // Declaração fora do if

    if (selectedAnoMes != null) {
        // Dividindo a string em duas partes: ano e mês
        [ano, mes] = selectedAnoMes.split('/');
    }




    return (
        <>
            <BrowserView>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {isloading ? (
                        <KpiSkeleton />
                    ) : (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20 }}> <b>Olá, {kpivisitas?.[0]?.consultor}.</b> </div>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20 }}> <b>Aqui estão os seus resultados
                                {selectedAnoMes !== mesAtual && selectedAnoMes !== null ? ' do mês ' + moment(new Date(selectedAnoMes)).format('MM') + ' de ' + moment(new Date(selectedAnoMes)).format('YYYY') : null}:</b> </div>
                            {selectedAnoMes === null || selectedAnoMes === mesAtual ? (<div style={{ display: 'flex', justifyContent: 'center', fontSize: 14 }}><b style={{ color: '#8c8c8c' }}>Última atualização: {moment(filteredData[0]?.dt_processamento).locale('pt-br').from(moment().locale('pt-br'))}.</b></div>) : (null)}
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px' }}>
                                <Form>
                                    <Form.Group widths='1' color='gray'>
                                        <div className="field">
                                            <div>
                                                <StyledDropdown
                                                    options={formattedAnoMes}
                                                    selection
                                                    placeholder='Ano/Mês'
                                                    name='anomes'
                                                    fluid
                                                    value={selectedAnoMes ? selectedAnoMes : arrayAnos[0]}
                                                    onChange={handleAnoSelect}
                                                    scrolling
                                                />
                                            </div>

                                        </div>
                                    </ Form.Group>
                                </ Form>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                    <span style={{ marginRight: 20 }}>Ver mais indicadores: </span>
                                    <div class="toggle">
                                        <input type="checkbox" checked={checked} onChange={handleChange} />
                                        <label></label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '60px', flexDirection: 'row', marginTop: '-20px' }}>
                                {filteredData.map((item, index) => (
                                    <>
                                        <CardDashboard nome='Visitas Total'
                                            projetada={item.visitas_total_projetada}
                                            meta={item.visitas_total_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            iconQtde={iconComponent(item.visitas_total_qtde, 'visitas_total_qtde', index)}
                                            qtde={item.visitas_total_qtde}
                                            iconPontos={iconComponent(item.visitas_total_pontos, 'visitas_total_pontos', index)}
                                            pontos={item.visitas_total_pontos}
                                            cor={item.visitas_total_cor} />

                                        <CardDashboard nome='Visitas Novos'
                                            projetada={item.visitas_novos_projetada}
                                            meta={item.visitas_novos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_novos_qtde}
                                            iconQtde={iconComponent(item.visitas_novos_qtde, 'visitas_novos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_novos_pontos, 'visitas_novos_pontos', index)}
                                            pontos={item.visitas_novos_pontos} cor={item.visitas_novos_cor} />

                                        <CardDashboard nome='Visitas Inativos'
                                            projetada={item.visitas_inativos_projetada}
                                            meta={item.visitas_inativos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_inativos_qtde}
                                            iconQtde={iconComponent(item.visitas_inativos_qtde, 'visitas_inativos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_inativos_pontos, 'visitas_inativos_pontos', index)}
                                            pontos={item.visitas_inativos_pontos} cor={item.visitas_inativos_cor} />

                                        <CardDashboard nome='Visitas Ativos'
                                            projetada={item.visitas_ativos_projetada}
                                            meta={item.visitas_ativos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_ativos_qtde}
                                            iconQtde={iconComponent(item.visitas_ativos_qtde, 'visitas_ativos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_ativos_pontos, 'visitas_ativos_pontos', index)}
                                            pontos={item.visitas_ativos_pontos} cor={item.visitas_ativos_cor} />

                                        {checked && (
                                            <>
                                                <CardDashboard
                                                    nome='Faturamento Total'
                                                    qtde={tratarNumero(item.faturamento_total_vlr)}
                                                    projetada={tratarNumero(item.faturamento_total_projetada)}
                                                    meta={tratarNumero(item.faturamento_total_meta)}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    iconQtde={iconComponent(item.faturamento_total_vlr, 'faturamento_total_vlr', index)}
                                                    iconPontos={iconComponent(item.faturamento_total_pontos, 'faturamento_total_pontos', index)}
                                                    pontos={item.faturamento_total_pontos} cor={item.faturamento_total_cor} />

                                                <CardDashboard nome='Meta'
                                                    projetada={Number(item.meta_pct_projetada).toFixed(1) + '%'}
                                                    // meta={Number(item.meta_pct_meta).toFixed(1) + '%'}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.meta_vlr)}
                                                    iconPremio={iconComponent(item.meta_vlr, 'meta_vlr', index)}
                                                    qtde={Number(item.meta_pct).toFixed(1) + '%'}
                                                    iconQtde={iconComponent(item.meta_pct, 'meta_pct', index)}
                                                    iconPontos={iconComponent(item.meta_pontos, 'meta_pontos', index)}
                                                    pontos={item.meta_pontos}
                                                    cor={item.meta_cor} />

                                                <CardDashboard nome='Cliente Abertura'
                                                    projetada={item.cliente_abertura_projetada}
                                                    meta={item.cliente_abertura_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_abertura_vlr)}
                                                    qtde={item.cliente_abertura_qtde}
                                                    iconPremio={iconComponent(item.cliente_abertura_vlr, 'cliente_abertura_vlr', index)}
                                                    iconQtde={iconComponent(item.cliente_abertura_qtde, 'cliente_abertura_qtde', index)}
                                                    iconPontos={iconComponent(item.cliente_abertura_pontos, 'cliente_abertura_pontos', index)}
                                                    pontos={item.cliente_abertura_pontos}
                                                    cor={item.cliente_abertura_cor} />


                                                <CardDashboard nome='Cliente Reativação'
                                                    projetada={item.cliente_reativacao_projetada}
                                                    meta={item.cliente_reativacao_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_reativacao_vlr)}
                                                    qtde={item.cliente_reativacao_qtde}
                                                    iconPremio={iconComponent(item.cliente_reativacao_vlr, 'cliente_reativacao_vlr', index)}
                                                    iconQtde={iconComponent(item.cliente_reativacao_qtde, 'cliente_reativacao_qtde', index)}
                                                    iconPontos={iconComponent(item.cliente_reativacao_pontos, 'cliente_reativacao_pontos', index)}
                                                    pontos={item.cliente_reativacao_pontos}
                                                    cor={item.cliente_reativacao_cor} />

                                                <CardDashboard nome='Venda Display'
                                                    projetada={item.venda_display_projetada}
                                                    meta={item.venda_display_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_display_vlr)}
                                                    qtde={item.venda_display_qtde}
                                                    iconPremio={iconComponent(item.venda_display_vlr, 'venda_display_vlr', index)}
                                                    iconQtde={iconComponent(item.venda_display_qtde, 'venda_display_qtde', index)}
                                                    iconPontos={iconComponent(item.venda_display_pontos, 'venda_display_pontos', index)}
                                                    pontos={item.venda_display_pontos}
                                                    cor={item.venda_display_cor} />

                                                <CardDashboard nome='Venda Book'
                                                    projetada={item.venda_book_projetada}
                                                    meta={item.venda_book_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_book_vlr)}
                                                    qtde={item.venda_book_qtde}
                                                    iconPremio={iconComponent(item.venda_book_vlr, 'venda_book_vlr', index)}
                                                    iconQtde={iconComponent(item.venda_book_qtde, 'venda_book_qtde', index)}
                                                    iconPontos={iconComponent(item.venda_book_pontos, 'venda_book_pontos', index)}
                                                    pontos={item.venda_book_pontos}
                                                    cor={item.venda_book_cor} />

                                                <CardDashboard nome='Pontos Total' qtde={'-'}
                                                    pontosprojetada={item.pontos_total_projetada}
                                                    pontosmeta={item.pontos_total_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.premio_total)}
                                                    iconPremio={iconComponent(item.premio_total, 'premio_total', index)}
                                                    iconPontos={iconComponent(item.pontos_total, 'pontos_total', index)}
                                                    pontos={item.pontos_total}
                                                    cor={item.pontos_total_cor} />
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                            <CardGraph />

                        </>
                    )}

                </div>
            </BrowserView>

            <MobileView>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {isloading ? (
                        <KpiSkeletonMobile />
                    ) : (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20 }}> <b>Olá, {kpivisitas?.[0]?.consultor}</b> </div>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 20, textAlign: 'center' }}> <b>Aqui estão os seus resultados
                                {selectedAnoMes !== mesAtual && selectedAnoMes !== null ? ' do mês ' + mes + ' de ' + ano : null}:</b> </div>
                            {selectedAnoMes === null || selectedAnoMes === mesAtual ? (<div style={{ display: 'flex', justifyContent: 'center', fontSize: 14 }}><b style={{ color: '#8c8c8c' }}>Última atualização: {moment(filteredData[0]?.dt_processamento).locale('pt-br').from(moment().locale('pt-br'))}.</b></div>) : (null)}
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px', marginTop: 20 }}>
                                <Form>
                                    <Form.Group widths='1' color='gray'>
                                        <div className="field">
                                            <div>
                                                <StyledDropdown
                                                    options={formattedAnoMes}
                                                    selection
                                                    placeholder='Ano/Mês'
                                                    name='anomes'
                                                    fluid
                                                    value={selectedAnoMes ? selectedAnoMes : arrayAnos[0]}
                                                    onChange={handleAnoSelect}
                                                    scrolling
                                                />
                                            </div>

                                        </div>
                                    </ Form.Group>
                                </ Form>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                    <span style={{ marginRight: 5 }}>Mais indicadores: </span>
                                    <div class="toggle">
                                        <input type="checkbox" checked={checked} onChange={handleChange} />
                                        <label></label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%', flex: 1, justifyContent: 'space-between' }}>
                                {filteredData.map((item, index) => (
                                    <>
                                        <CardDashboard mobile nome='Visitas Total'
                                            projetada={item.visitas_total_projetada}
                                            meta={item.visitas_total_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            iconQtde={iconComponent(item.visitas_total_qtde, 'visitas_total_qtde', index)}
                                            qtde={item.visitas_total_qtde}
                                            iconPontos={iconComponent(item.visitas_total_pontos, 'visitas_total_pontos', index)}
                                            pontos={item.visitas_total_pontos}
                                            cor={item.visitas_total_cor} />

                                        <CardDashboard mobile nome='Visitas Novos'
                                            projetada={item.visitas_novos_projetada}
                                            meta={item.visitas_novos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_novos_qtde}
                                            iconQtde={iconComponent(item.visitas_novos_qtde, 'visitas_novos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_novos_pontos, 'visitas_novos_pontos', index)}
                                            pontos={item.visitas_novos_pontos} cor={item.visitas_novos_cor} />

                                        <CardDashboard mobile nome='Visitas Inativos'
                                            projetada={item.visitas_inativos_projetada}
                                            meta={item.visitas_inativos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_inativos_qtde}
                                            iconQtde={iconComponent(item.visitas_inativos_qtde, 'visitas_inativos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_inativos_pontos, 'visitas_inativos_pontos', index)}
                                            pontos={item.visitas_inativos_pontos} cor={item.visitas_inativos_cor} />

                                        <CardDashboard mobile nome='Visitas Ativos'
                                            projetada={item.visitas_ativos_projetada}
                                            meta={item.visitas_ativos_meta}
                                            showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                            ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                            qtde={item.visitas_ativos_qtde}
                                            iconQtde={iconComponent(item.visitas_ativos_qtde, 'visitas_ativos_qtde', index)}
                                            iconPontos={iconComponent(item.visitas_ativos_pontos, 'visitas_ativos_pontos', index)}
                                            pontos={item.visitas_ativos_pontos} cor={item.visitas_ativos_cor} />

                                        {checked && (
                                            <>
                                                <CardDashboard mobile
                                                    nome='Faturamento Total'
                                                    qtde={tratarNumero(item.faturamento_total_vlr)}
                                                    projetada={tratarNumero(item.faturamento_total_projetada)}
                                                    meta={tratarNumero(item.faturamento_total_meta)}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    iconQtde={iconComponent(item.faturamento_total_vlr, 'faturamento_total_vlr', index)}
                                                    iconPontos={iconComponent(item.faturamento_total_pontos, 'faturamento_total_pontos', index)}
                                                    pontos={item.faturamento_total_pontos} cor={item.faturamento_total_cor} />

                                                <CardDashboard mobile nome='Meta'
                                                    projetada={Number(item.meta_pct_projetada).toFixed(1) + '%'}
                                                    // meta={Number(item.meta_pct_meta).toFixed(1) + '%'}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.meta_vlr)}
                                                    iconPremio={iconComponent(item.meta_vlr, 'meta_vlr', index)}
                                                    qtde={Number(item.meta_pct).toFixed(1) + '%'}
                                                    iconQtde={iconComponent(item.meta_pct, 'meta_pct', index)}
                                                    iconPontos={iconComponent(item.meta_pontos, 'meta_pontos', index)}
                                                    pontos={item.meta_pontos}
                                                    cor={item.meta_cor} />

                                                <CardDashboard mobile nome='Cliente Abertura'
                                                    projetada={item.cliente_abertura_projetada}
                                                    meta={item.cliente_abertura_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_abertura_vlr)}
                                                    qtde={item.cliente_abertura_qtde}
                                                    iconPremio={iconComponent(item.cliente_abertura_vlr, 'cliente_abertura_vlr', index)}
                                                    iconQtde={iconComponent(item.cliente_abertura_qtde, 'cliente_abertura_qtde', index)}
                                                    iconPontos={iconComponent(item.cliente_abertura_pontos, 'cliente_abertura_pontos', index)}
                                                    pontos={item.cliente_abertura_pontos}
                                                    cor={item.cliente_abertura_cor} />


                                                <CardDashboard mobile nome='Cliente Reativação'
                                                    projetada={item.cliente_reativacao_projetada}
                                                    meta={item.cliente_reativacao_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.cliente_reativacao_vlr)}
                                                    qtde={item.cliente_reativacao_qtde}
                                                    iconPremio={iconComponent(item.cliente_reativacao_vlr, 'cliente_reativacao_vlr', index)}
                                                    iconQtde={iconComponent(item.cliente_reativacao_qtde, 'cliente_reativacao_qtde', index)}
                                                    iconPontos={iconComponent(item.cliente_reativacao_pontos, 'cliente_reativacao_pontos', index)}
                                                    pontos={item.cliente_reativacao_pontos}
                                                    cor={item.cliente_reativacao_cor} />

                                                <CardDashboard mobile nome='Venda Display'
                                                    projetada={item.venda_display_projetada}
                                                    meta={item.venda_display_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_display_vlr)}
                                                    qtde={item.venda_display_qtde}
                                                    iconPremio={iconComponent(item.venda_display_vlr, 'venda_display_vlr', index)}
                                                    iconQtde={iconComponent(item.venda_display_qtde, 'venda_display_qtde', index)}
                                                    iconPontos={iconComponent(item.venda_display_pontos, 'venda_display_pontos', index)}
                                                    pontos={item.venda_display_pontos}
                                                    cor={item.venda_display_cor} />

                                                <CardDashboard mobile nome='Venda Book'
                                                    projetada={item.venda_book_projetada}
                                                    meta={item.venda_book_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.venda_book_vlr)}
                                                    qtde={item.venda_book_qtde}
                                                    iconPremio={iconComponent(item.venda_book_vlr, 'venda_book_vlr', index)}
                                                    iconQtde={iconComponent(item.venda_book_qtde, 'venda_book_qtde', index)}
                                                    iconPontos={iconComponent(item.venda_book_pontos, 'venda_book_pontos', index)}
                                                    pontos={item.venda_book_pontos}
                                                    cor={item.venda_book_cor} />

                                                <CardDashboard mobile nome='Pontos Total' qtde={'-'}
                                                    pontosprojetada={item.pontos_total_projetada}
                                                    pontosmeta={item.pontos_total_meta}
                                                    showPremio={possuiMesesAnteriores && moment(item.dt_ref).format('YYYY/MM') !== mesAtual ? true : false}
                                                    ismesatual={moment(item.dt_ref).format('YYYY/MM') === mesAtual}
                                                    premio={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.premio_total)}
                                                    iconPremio={iconComponent(item.premio_total, 'premio_total', index)}
                                                    iconPontos={iconComponent(item.pontos_total, 'pontos_total', index)}
                                                    pontos={item.pontos_total}
                                                    cor={item.pontos_total_cor} />
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>

                        </>
                    )}

                </div>
            </MobileView>
        </>
    );

}

const mapStateToProps = (state) => ({
    kpivisitas: state.KpiVisitasReducer.kpivisitas,
    isloading: state.KpiVisitasReducer.isloading,
    codigoConsultor: state.LoginReducer.codigoConsultor
})

export default connect(mapStateToProps, {
    kpivisitasListAction
}
)(KpiConsultor);
