import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
//import * as serviceWorker from './registerServiceWorker';
import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter } from 'react-router-dom'

import reducers from './reducers'
import { Provider } from 'react-redux'
import { thunk } from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

import timerMiddleware from 'redux-timer';

const logger = store => next => action => {
  //console.group(action.type)
  //console.info('dispatching', action)
  let result = next(action)
  //console.log('next state', store.getState())
  //console.groupEnd(action.type)
  return result
}

const persistConfig = {
  key: 'root',
  whitelist: ['LoginReducer'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(
    applyMiddleware(logger, thunk, timerMiddleware)
  )
);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root')); // Atualize aqui


root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
//registerServiceWorker();
serviceWorker.register();
