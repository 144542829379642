import {
    KPIVISITAS_LIST, KPIVISITAS_LOADING
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    kpivisitas: [],
    isloading: true,
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case KPIVISITAS_LIST:
            return { ...state, kpivisitas: action.payload }
        case KPIVISITAS_LOADING:
            return { ...state, isloading: action.payload }

        default:
            return state;
    }
}